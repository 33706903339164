import React from 'react';
import { Table, theme } from 'antd';
import { MainContent } from '../../components/MainContent';
import { useQuery } from '@apollo/client';
import { ColumnsType } from 'antd/es/table';
import { useNavigate } from 'react-router';
import { PageHeader } from '@ant-design/pro-components';
import { tableLinkHoverRow } from '../../styles/globalCss';
import { graphql } from '../../graphql/generated';
import { LabsQuery } from '../../graphql/generated/graphql.ts';

const { useToken } = theme;

const LABS_QUERY = graphql(`
  query Labs {
    labs {
      id
      name
      shortName
      longName
    }
  }
`);

export type Lab = NonNullable<LabsQuery['labs']>[number];

export const Labs: React.FC = () => {
  const { token } = useToken();
  const navigate = useNavigate();
  const { data, loading } = useQuery(LABS_QUERY);

  const columns: ColumnsType<Lab> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
    },
    {
      title: 'Kurzbezeichnung',
      dataIndex: 'shortName',
      key: 'shortName',
      ellipsis: true,
      width: '150px',
    },
    {
      title: 'Langbezeichnung',
      dataIndex: 'longName',
      key: 'longName',
      ellipsis: true,
    },
  ];

  return (
    <MainContent>
      <PageHeader title="Labors" style={{ padding: 0, paddingBottom: 'inherit' }} />
      <Table<Lab>
        scroll={{ x: 'max-content' }}
        rowKey={record => record.id}
        sticky={true}
        size="middle"
        showSorterTooltip={false}
        dataSource={data?.labs ?? []}
        pagination={false}
        loading={loading}
        columns={columns}
        rowClassName={tableLinkHoverRow(token.colorPrimary)}
        onRow={record => {
          return {
            onClick: () => navigate(`/admin/labors/${record.id}`),
          };
        }}
      />
    </MainContent>
  );
};
