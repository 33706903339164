import React, { useEffect, useState } from 'react';
import { MainContent } from '../../../components/MainContent';
import { useParams } from 'react-router';
import { Button, Flex, Input, Table, Tag, theme, Tooltip } from 'antd';
import { useQuery } from '@apollo/client';
import { ColumnsType } from 'antd/es/table';
import { CheckOutlined, EditOutlined, InfoCircleOutlined, PlusCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { useDebounce } from 'use-debounce';
import { CreateSpecimenModal } from './specimens/CreateSpecimenModal';
import { UpdateSpecimenModal } from './specimens/UpdateSpecimenModal';
import { TestTubeColor } from '../../../components/TestTubeColor';
import { Markable } from '../../../components/Markable';
import {
  testTubeColorProperties,
  translateSpecimenFeature,
  translateSpecimenGroup,
  translateTestTubeSupplier,
} from '../../../utils/enumHelpers';
import { humanizedHours } from '../../../utils/dateFormatUtils';
import { tableActionCell } from '../../../styles/globalCss';
import { graphql } from '../../../graphql/generated';
import { SpecimensQuery } from '../../../graphql/generated/graphql.ts';
import { TableList } from '../../../components/TableList.tsx';

const { useToken } = theme;

const SPECIMENS_QUERY = graphql(`
  query Specimens($labId: ID!) {
    labSpecimens(labId: $labId) {
      id
      name
      storagePeriod
      classification
      features
      group
      requiresLocalizations
      localizationInputEnabled
      predefinedLocalizations
      testTubes {
        id
        name
        supplier
        color
      }
      forms {
        id
        name
      }
    }

    labTestTubes(labId: $labId) {
      id
      name
      supplier
      color
    }

    labForms(labId: $labId) {
      id
      name
    }
  }
`);

export type Specimen = NonNullable<SpecimensQuery['labSpecimens']>[number];
export type TestTube = NonNullable<SpecimensQuery['labTestTubes']>[number];
export type Form = NonNullable<SpecimensQuery['labForms']>[number];

export const Specimens: React.FC = () => {
  const { token } = useToken();
  const { id: labId } = useParams<{ id: string }>();
  const [search, setSearch] = useState<string | null>(null);
  const [debouncedSearch] = useDebounce(search, 250);
  const [filteredSpecimens, setFilteredSpecimens] = useState<Specimen[]>([]);
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [editSpecimen, setEditSpecimen] = useState<Specimen | null>(null);

  const { data, loading, refetch } = useQuery(SPECIMENS_QUERY, {
    variables: {
      labId: labId ?? '',
    },
    pollInterval: 60000,
  });

  const columns: ColumnsType<Specimen> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: value => <Markable tokens={debouncedSearch ?? ''}>{value}</Markable>,
    },
    {
      title: 'Materialkennung',
      dataIndex: 'classification',
      key: 'classification',
      ellipsis: true,
      sorter: (a, b) => a.classification.localeCompare(b.classification),
      render: value => <Markable tokens={debouncedSearch ?? ''}>{value}</Markable>,
    },
    {
      title: 'Gruppe',
      dataIndex: 'group',
      key: 'group',
      ellipsis: true,
      sorter: (a, b) => a.group.localeCompare(b.group),
      render: value => translateSpecimenGroup(value),
    },
    {
      title: 'Lagerdauer (Stunden)',
      dataIndex: 'storagePeriod',
      key: 'storagePeriod',
      ellipsis: true,
      sorter: (a, b) => (a.storagePeriod === b.storagePeriod ? 0 : a.storagePeriod > b.storagePeriod ? 1 : -1),
      render: value => <Tooltip title={humanizedHours(value)}>{value}</Tooltip>,
    },
    {
      title: 'Lokalisationen',
      dataIndex: 'requiresLocalizations',
      key: 'requiresLocalizations',
      width: 140,
      sorter: (a, b) => Number(a.requiresLocalizations) - Number(b.requiresLocalizations),
      render: value => (value ? <CheckOutlined /> : ''),
    },
    {
      title: 'Probenbehälter',
      dataIndex: 'testTubes',
      key: 'testTubes',
      ellipsis: true,
      render: (_, record) =>
        record.testTubes.map(tt => (
          <Tooltip key={tt.id} title={tt.name}>
            <Tag>
              <Flex gap={4} align="center">
                {translateTestTubeSupplier(tt.supplier)}{' '}
                <TestTubeColor properties={testTubeColorProperties(tt.color)} withText={false} />
              </Flex>
            </Tag>
          </Tooltip>
        )),
    },
    {
      title: 'Eigenschaften',
      dataIndex: 'features',
      key: 'features',
      ellipsis: true,
      render: (_, record) => record.features.map(f => <Tag key={f}>{translateSpecimenFeature(f)}</Tag>),
    },
    {
      title: 'Formulare',
      dataIndex: 'forms',
      key: 'forms',
      render: (_, record) => <TableList entries={record.forms.map(it => it.name)} maxEntries={3} />,
    },
    {
      title: '',
      key: 'actions',
      fixed: 'right',
      align: 'right',
      ellipsis: true,
      width: '50px',
      className: tableActionCell,
      render: (_, record) => <Button icon={<EditOutlined />} type="text" onClick={() => setEditSpecimen(record)} />,
    },
  ];

  useEffect(() => {
    const filtered = data?.labSpecimens?.filter(s => {
      if (debouncedSearch && debouncedSearch.length) {
        const searchValue = debouncedSearch.toLowerCase();
        return s.name.toLowerCase().includes(searchValue) || s.classification.toLowerCase().includes(searchValue);
      }
      return true;
    });
    setFilteredSpecimens(filtered ?? []);
  }, [debouncedSearch, data]);

  return (
    <MainContent>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: token.paddingSM }}>
        <Input
          allowClear
          autoFocus
          placeholder="Suche"
          value={search ?? ''}
          onChange={e => setSearch(e.target.value)}
          prefix={<SearchOutlined />}
          suffix={
            <Tooltip title="Suche nach Name oder Materialkennung">
              <InfoCircleOutlined />
            </Tooltip>
          }
          style={{ width: '300px', marginRight: token.paddingSM }}
        />
        <Button
          type="primary"
          icon={<PlusCircleOutlined />}
          disabled={loading}
          onClick={() => setCreateModalVisible(true)}
        >
          Neues Material
        </Button>
      </div>
      <Table<Specimen>
        scroll={{ x: 'max-content' }}
        rowKey={record => record.id}
        size="middle"
        showSorterTooltip={false}
        dataSource={filteredSpecimens}
        pagination={{
          showQuickJumper: true,
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]} bis ${range[1]} von ${total} Materialien`,
        }}
        loading={loading}
        columns={columns}
      />
      <CreateSpecimenModal
        modalVisible={createModalVisible}
        onSuccess={() => refetch()}
        onClose={() => setCreateModalVisible(false)}
        labId={labId ?? ''}
        specimens={data?.labSpecimens ?? []}
        testTubes={data?.labTestTubes ?? []}
        forms={data?.labForms ?? []}
      />
      <UpdateSpecimenModal
        specimen={editSpecimen}
        onSuccess={() => refetch()}
        onClose={() => setEditSpecimen(null)}
        specimens={data?.labSpecimens ?? []}
        testTubes={data?.labTestTubes ?? []}
        forms={data?.labForms ?? []}
      />
    </MainContent>
  );
};
