import React, { useEffect } from 'react';
import { App, Checkbox, Form, Modal, Select, Typography } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { useForm } from 'antd/es/form/Form';
import { graphql } from '../../../../graphql/generated';
import { LoadingIndicator } from '../../../../components/LoadingIndicator.tsx';
import { CopyCatalogParametersInput } from '../../../../graphql/generated/graphql.ts';

const COPY_CATALOG_PARAMETERS_MUTATION = graphql(`
  mutation CopyCatalogParametersMut($input: CopyCatalogParametersInput!) {
    copyCatalogParameters(input: $input)
  }
`);

const COPY_CATALOG_PARAMETERS_QUERY = graphql(`
  query CopyCatalogParameters($catalogId: ID!, $labId: ID!) {
    catalog(id: $catalogId) {
      id
      name
      catalogParameters {
        id
        parameter {
          id
          shortName
          longName
        }
      }
    }

    labCatalogs(labId: $labId) {
      id
      name
    }
  }
`);

export const CopyCatalogParametersModal: React.FC<{
  onClose: () => void;
  sourceCatalogId: string | null;
  labId: string | null | undefined;
}> = ({ onClose, sourceCatalogId, labId }) => {
  const [copyCatalogParametersMutation] = useMutation(COPY_CATALOG_PARAMETERS_MUTATION);
  const [form] = useForm<CopyCatalogParametersInput>();
  const { message } = App.useApp();

  const update = async (values: CopyCatalogParametersInput) => {
    try {
      await copyCatalogParametersMutation({
        variables: {
          input: {
            ...values,
            sourceCatalogId: sourceCatalogId!,
          },
        },
      });

      message.success('Katalog-Parameter wurden kopiert');
      onClose();
    } catch {
      message.error('Katalog-Parameter konnten nicht kopiert werden');
    }
  };

  useEffect(() => {
    form.resetFields();
  }, [form, sourceCatalogId, labId]);

  const { data, loading } = useQuery(COPY_CATALOG_PARAMETERS_QUERY, {
    variables: {
      catalogId: sourceCatalogId!,
      labId: labId!,
    },
    skip: !sourceCatalogId || !labId,
  });

  return (
    <Modal
      width={800}
      title={`Katalog-Parameter von »${data?.catalog?.name}« kopieren`}
      okText="Kopieren"
      okButtonProps={{ disabled: loading, loading: loading, icon: <EditOutlined /> }}
      open={!!sourceCatalogId}
      onCancel={onClose}
      onOk={form.submit}
      destroyOnClose
    >
      {loading ? (
        <LoadingIndicator height="200px" />
      ) : (
        <Form<CopyCatalogParametersInput>
          layout="vertical"
          form={form}
          onFinish={update}
          initialValues={{
            overrideExisting: false,
          }}
        >
          <Form.Item name="targetCatalogIds" label="Ziel-Kataloge" rules={[{ required: true, type: 'array', min: 1 }]}>
            <Select
              showSearch
              allowClear
              mode="multiple"
              placeholder="Suche nach Katalogen"
              optionFilterProp="search"
              options={[...(data?.labCatalogs?.filter(it => it.id !== sourceCatalogId) ?? [])]
                .sort((a, b) => a.name.localeCompare(b.name))
                .map(it => ({
                  label: it.name,
                  value: it.id,
                  search: it.name,
                }))}
            />
          </Form.Item>

          <Form.Item
            name="catalogParameterIds"
            label="Quell-Katalog-Parameter"
            rules={[{ required: true, type: 'array', min: 1 }]}
          >
            <Select
              showSearch
              allowClear
              mode="multiple"
              placeholder="Suche nach Parameter"
              optionFilterProp="search"
              optionLabelProp="taglabel"
              options={[...(data?.catalog?.catalogParameters ?? [])]
                .sort((a, b) => a.parameter.shortName.localeCompare(b.parameter.shortName))
                .map(it => ({
                  label: (
                    <>
                      <div>{it.parameter.shortName}</div>
                      <Typography.Text type="secondary">{it.parameter.longName}</Typography.Text>
                    </>
                  ),
                  value: it.id,
                  search: `${it.parameter.shortName} - ${it.parameter.longName}`,
                  taglabel: it.parameter.shortName,
                }))}
            />
          </Form.Item>

          <Form.Item name="overrideExisting" valuePropName="checked">
            <Checkbox>Bestehende Parameter in Ziel-Katalogen überschreiben</Checkbox>
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};
