import React, { ReactNode, useEffect, useState } from 'react';
import { Card, Checkbox, Col, Modal, Row, Space, theme, Typography } from 'antd';
import { Localization, SelectedParameter, useAppStore } from '../../../hooks/store/useAppStore';
import { EditableTagGroup } from '../../../components/EditableTagGroup.tsx';
import { css } from '@emotion/css';
import { useShallow } from 'zustand/react/shallow';
import { useQuery } from '@apollo/client';
import { graphql } from '../../../graphql/generated';
import { LoadingIndicator } from '../../../components/LoadingIndicator.tsx';

type Error = { key: string; msg: string };

const LOCALIZATIONS_CHOOSER_QUERY = graphql(`
  query LocalizationsChooser($id: ID!) {
    request(id: $id) {
      id
      orders {
        id
        orderLabels {
          id
          specimenId
          location
        }
      }
    }
  }
`);

export const validateLocalizations = (selectedParams: SelectedParameter[], localizations: Localization[]) => {
  const errors: Error[] = [];
  selectedParams
    .filter(it => it.specimens.some(s => s.requiresLocalizations))
    .forEach(param => {
      param.specimens
        .filter(it => it.requiresLocalizations)
        .forEach(spec => {
          if (!localizations.some(loc => loc.specimenId === spec.id && loc.paramId === param.id)) {
            errors.push({ key: param.id + '_' + spec.id, msg: 'Mind. eine Lokalisation erforderlich' });
          }
        });
    });
  return errors;
};

export const LocalizationsChooserModal: React.FC<{
  selectedParams: SelectedParameter[];
  flipParamNames: boolean;
  requestIdForReorder: string;
  open: boolean;
  okText: string;
  okIcon: ReactNode;
  closable: boolean;
  onClose: () => void;
  onSuccess: () => void;
}> = ({ selectedParams, flipParamNames, requestIdForReorder, open, okText, okIcon, closable, onClose, onSuccess }) => {
  const { token } = theme.useToken();

  const [errors, setErrors] = useState<Error[]>([]);
  const [okClicked, setOkClicked] = useState(false);
  const { localizations, setLocalizations } = useAppStore(
    useShallow(state => ({
      localizations: state.localizations,
      setLocalizations: state.setLocalizations,
    }))
  );

  const { data, loading } = useQuery(LOCALIZATIONS_CHOOSER_QUERY, {
    variables: {
      id: requestIdForReorder,
    },
    skip: !requestIdForReorder,
  });

  useEffect(() => {
    setOkClicked(false);
  }, [open]);

  const onOk = () => {
    setOkClicked(true);
    const errors = validateLocalizations(selectedParams, localizations);
    setErrors(errors);
    if (errors.length > 0) {
      return;
    }
    onSuccess();
  };

  return (
    <Modal
      width={600}
      title="Bitte geben Sie Lokalisationen an"
      okText={okText}
      okButtonProps={{ icon: okIcon }}
      closable={closable}
      keyboard={closable}
      maskClosable={closable}
      cancelButtonProps={{ hidden: !closable }}
      open={open}
      onCancel={onClose}
      onOk={onOk}
      destroyOnClose
    >
      {loading ? (
        <LoadingIndicator height="200px" />
      ) : (
        <Space
          direction="vertical"
          className={css`
            width: 100%;
            max-height: 450px;
            overflow-y: auto;
          `}
        >
          {selectedParams
            .filter(it => it.specimens.some(s => s.requiresLocalizations))
            .map(param => (
              <Card
                size="small"
                type="inner"
                title={
                  flipParamNames ? (
                    <>
                      {param.longName}{' '}
                      <Typography.Text
                        className={css`
                          font-weight: normal;
                        `}
                        type="secondary"
                      >
                        {param.shortName}
                      </Typography.Text>
                    </>
                  ) : (
                    <>
                      {param.shortName}{' '}
                      <Typography.Text
                        className={css`
                          font-weight: normal;
                        `}
                        type="secondary"
                      >
                        {param.longName}
                      </Typography.Text>
                    </>
                  )
                }
                key={param.id}
              >
                {param.specimens
                  .filter(it => it.requiresLocalizations)
                  .map(spec => (
                    <Row
                      gutter={[16, 0]}
                      key={param.id + '_' + spec.id}
                      className={css`
                        margin-bottom: ${token.marginSM}px;

                        :last-child {
                          margin-bottom: 0;
                        }
                      `}
                    >
                      <Col span={8}>
                        <h4>{spec.name}</h4>
                      </Col>
                      <Col span={16}>
                        <Space direction="vertical">
                          {!requestIdForReorder ? (
                            <>
                              {spec.predefinedLocalizations.map(preLoc => (
                                <Checkbox
                                  key={preLoc}
                                  checked={localizations.some(
                                    loc =>
                                      loc.paramId === param.id && loc.specimenId === spec.id && loc.location === preLoc
                                  )}
                                  onChange={e => {
                                    let newLocalizations;
                                    if (e.target.checked) {
                                      // add
                                      newLocalizations = [
                                        ...localizations,
                                        {
                                          location: preLoc,
                                          paramId: param.id,
                                          specimenId: spec.id,
                                        },
                                      ];
                                    } else {
                                      // remove
                                      newLocalizations = localizations.filter(
                                        loc =>
                                          loc.paramId !== param.id ||
                                          loc.specimenId !== spec.id ||
                                          loc.location !== preLoc
                                      );
                                    }
                                    const errors = validateLocalizations(selectedParams, newLocalizations);
                                    setErrors(errors);
                                    setLocalizations(newLocalizations);
                                  }}
                                >
                                  {preLoc}
                                </Checkbox>
                              ))}
                              {spec.localizationInputEnabled && (
                                <EditableTagGroup
                                  addLabel="Neue Hinzufügen"
                                  inputMaxLength={15}
                                  tags={localizations
                                    .filter(it => it.paramId === param.id && it.specimenId === spec.id)
                                    .filter(it => !spec.predefinedLocalizations.includes(it.location))
                                    .map(it => it.location)}
                                  inputWidth="140px"
                                  onChange={tags => {
                                    const newLocalizations = [
                                      ...localizations.filter(
                                        it =>
                                          (it.paramId === param.id &&
                                            it.specimenId === spec.id &&
                                            spec.predefinedLocalizations.includes(it.location)) ||
                                          !(it.paramId === param.id && it.specimenId === spec.id)
                                      ),
                                      ...tags.map(tag => ({
                                        location: tag,
                                        paramId: param.id,
                                        specimenId: spec.id,
                                      })),
                                    ];
                                    const errors = validateLocalizations(selectedParams, newLocalizations);
                                    setErrors(errors);
                                    setLocalizations(newLocalizations);
                                  }}
                                />
                              )}
                            </>
                          ) : (
                            data?.request?.orders
                              .flatMap(it => it.orderLabels)
                              .filter(it => it.specimenId === spec.id && !!it.location.length)
                              .map(it => (
                                <Checkbox
                                  key={it.id}
                                  checked={localizations.some(
                                    loc =>
                                      loc.paramId === param.id &&
                                      loc.specimenId === spec.id &&
                                      loc.location === it.location
                                  )}
                                  onChange={e => {
                                    let newLocalizations;
                                    if (e.target.checked) {
                                      // add
                                      newLocalizations = [
                                        ...localizations,
                                        {
                                          location: it.location,
                                          paramId: param.id,
                                          specimenId: spec.id,
                                        },
                                      ];
                                    } else {
                                      // remove
                                      newLocalizations = localizations.filter(
                                        loc =>
                                          loc.paramId !== param.id ||
                                          loc.specimenId !== spec.id ||
                                          loc.location !== it.location
                                      );
                                    }
                                    const errors = validateLocalizations(selectedParams, newLocalizations);
                                    setErrors(errors);
                                    setLocalizations(newLocalizations);
                                  }}
                                >
                                  {it.location}
                                </Checkbox>
                              ))
                          )}
                          <Typography.Text type="danger">
                            {okClicked && errors.find(e => e.key === param.id + '_' + spec.id)?.msg}
                          </Typography.Text>
                        </Space>
                      </Col>
                    </Row>
                  ))}
              </Card>
            ))}
        </Space>
      )}
    </Modal>
  );
};
