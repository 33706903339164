import React, { useEffect, useState } from 'react';
import { App, Button, DatePicker, Select, Space } from 'antd';
import { MainContent } from '../components/MainContent';
import { PageHeader } from '@ant-design/pro-components';
import { CloudDownloadOutlined } from '@ant-design/icons';
import { useAuth } from 'react-oidc-context';
import { saveAs } from 'file-saver';
import { graphql } from '../graphql/generated';
import { FibuExportQuery } from '../graphql/generated/graphql.ts';
import { useQuery } from '@apollo/client';
import { LoadingIndicator } from '../components/LoadingIndicator.tsx';
import dayjs from 'dayjs';
import { API_DATE_FORMAT, SHORT_DATE_FORMAT } from '../utils/dateFormatUtils.ts';

const FIBU_EXPORT_QUERY = graphql(`
  query FibuExport {
    assignmentForCurrentUser {
      id
      primaryDoctor {
        id
        assignedLabs {
          lab {
            id
            name
            identifier
          }
        }
      }
    }
  }
`);

type LabType = NonNullable<FibuExportQuery['assignmentForCurrentUser']>['primaryDoctor']['assignedLabs'][number]['lab'];

export const FibuExport: React.FC = () => {
  const [from, setFrom] = useState<dayjs.Dayjs | null>(null);
  const [to, setTo] = useState<dayjs.Dayjs | null>(null);
  const [loading, setLoading] = useState(false);
  const auth = useAuth();
  const { message } = App.useApp();
  const [labs, setLabs] = useState<LabType[]>([]);
  const [selectedLab, setSelectedLab] = useState<LabType | null>(null);

  const { data } = useQuery(FIBU_EXPORT_QUERY);

  const download = async () => {
    if (Math.abs(from!.diff(to, 'years')) > 2) {
      message.error('Ein Zeitraum größer als 2 Jahre ist nicht möglich!');
      return;
    }
    setLoading(true);

    const url =
      window._env_.API_URL +
      '/rest/fibu-export?' +
      new URLSearchParams({
        labId: selectedLab!.id,
        from: from!.format(API_DATE_FORMAT),
        to: to!.format(API_DATE_FORMAT),
      }).toString();

    try {
      const response = await fetch(url, {
        headers: {
          authorization: `Bearer ${auth.user?.access_token}`,
        },
      });

      if (!response.ok) {
        message.error('Es ist ein Fehler aufgetreten');
        return;
      }

      const blob = await response.blob();
      saveAs(
        blob,
        `fibu-${selectedLab!.identifier}-${from!.format(API_DATE_FORMAT)}_${to!.format(API_DATE_FORMAT)}.csv`
      );
    } catch {
      message.error('Es ist ein Fehler aufgetreten');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!data) {
      return;
    }
    setLabs(data?.assignmentForCurrentUser?.primaryDoctor?.assignedLabs?.map(it => it.lab) ?? []);
  }, [data]);

  useEffect(() => {
    if (labs.length && !selectedLab) {
      setSelectedLab(labs[0]);
    }
  }, [labs, selectedLab]);

  if (!selectedLab) {
    return <LoadingIndicator />;
  }

  return (
    <MainContent size="small">
      <PageHeader title="Fibu-Export" style={{ padding: 0, paddingBottom: 'inherit' }} />
      <Space wrap>
        {labs.length > 1 && (
          <Select
            style={{ width: '200px' }}
            options={labs.map(it => ({ label: it.name, value: it.id }))}
            value={selectedLab.id}
            onChange={id => setSelectedLab(labs.find(it => it.id === id)!)}
          />
        )}
        <DatePicker.RangePicker
          allowEmpty={[false, false]}
          format={SHORT_DATE_FORMAT}
          presets={[
            { label: 'Heute', value: [dayjs(), dayjs()] },
            { label: 'Gestern', value: [dayjs().subtract(1, 'day'), dayjs().subtract(1, 'day')] },
            { label: 'Letzte 7 Tage', value: [dayjs().subtract(7, 'days'), dayjs()] },
            { label: 'Diese Woche', value: [dayjs().startOf('week'), dayjs()] },
            {
              label: 'Letzte Woche',
              value: [dayjs().subtract(1, 'week').startOf('week'), dayjs().subtract(1, 'week').endOf('week')],
            },
            { label: 'Dieser Monat', value: [dayjs().startOf('month'), dayjs()] },
            {
              label: 'Letzter Monat',
              value: [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')],
            },
          ]}
          disabledDate={current => current.isAfter(dayjs())}
          onChange={dates => {
            setFrom(dates && dates[0] ? dates[0] : null);
            setTo(dates && dates[1] ? dates[1] : null);
          }}
        />
        <Button
          type="primary"
          disabled={!selectedLab || !from || !to || loading}
          loading={loading}
          icon={<CloudDownloadOutlined />}
          onClick={download}
        >
          Download CSV
        </Button>
      </Space>
    </MainContent>
  );
};
