import React, { useEffect, useState } from 'react';
import { MainContent } from '../components/MainContent';
import { useQuery } from '@apollo/client';
import { PageHeader } from '@ant-design/pro-components';
import { graphql } from '../graphql/generated';
import { OpenFrontDeskRequestsQuery } from '../graphql/generated/graphql.ts';
import { Badge, Card, Col, Flex, Input, List, Row, theme, Tooltip, Typography } from 'antd';
import { css } from '@emotion/css';
import { useDebounce } from 'use-debounce';
import { useNavigate } from 'react-router';
import { InfoCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { Markable } from '../components/Markable.tsx';
import { LoadingIndicator } from '../components/LoadingIndicator.tsx';
import { useCurrentContextStore } from '../hooks/store/useCurrentContextStore.ts';

export const OPEN_FRONT_DESK_REQUESTS_QUERY = graphql(`
  query OpenFrontDeskRequests {
    openFrontDeskRequests {
      id
      patientData {
        title
        firstName
        lastName
        svnr
      }
      doctor {
        id
        name
        meAddress
        assignedLabs {
          customerId
        }
      }
      orders {
        id
        number
      }
    }
  }
`);

export type Request = NonNullable<OpenFrontDeskRequestsQuery['openFrontDeskRequests']>[number];

export const OpenFrontDeskRequests: React.FC = () => {
  const { token } = theme.useToken();
  const navigate = useNavigate();
  const [filteredData, setFilteredData] = useState<Map<string, Array<Request>>>(new Map());
  const [search, setSearch] = useState<string | null>(null);
  const [debouncedSearch] = useDebounce(search, 250);
  const { setCurrentDoctorId } = useCurrentContextStore();

  const { data, loading } = useQuery(OPEN_FRONT_DESK_REQUESTS_QUERY);

  useEffect(() => {
    if (!data) {
      return;
    }

    const searchTokens =
      debouncedSearch
        ?.toLowerCase()
        .split(' ')
        .filter(it => it !== '') ?? [];

    const filtered =
      data?.openFrontDeskRequests?.filter(r => {
        if (searchTokens.length) {
          return searchTokens.every(token => {
            return (
              r.patientData.firstName.toLowerCase().includes(token) ||
              r.patientData.lastName.toLowerCase().includes(token) ||
              r.patientData.svnr.toLowerCase().includes(token) ||
              r.doctor.name.toLowerCase().includes(token) ||
              r.doctor.meAddress.toLowerCase().includes(token) ||
              r.doctor.assignedLabs.map(it => it.customerId.toLowerCase()).some(it => it.includes(token)) ||
              r.orders.map(order => order.number.toLowerCase()).some(it => it.includes(token))
            );
          });
        }
        return true;
      }) ?? [];

    const result = filtered.reduce<Map<string, Array<Request>>>(
      (map, acc) => map.set(acc.doctor.name, [...(map.get(acc.doctor.name) ?? []), acc]),
      new Map()
    );
    setFilteredData(result);
  }, [data, debouncedSearch]);

  if (loading) {
    return <LoadingIndicator height="200px" />;
  }

  return (
    <MainContent>
      <PageHeader
        title="Übersicht offener Anforderungen"
        style={{ padding: 0, paddingBottom: 'inherit' }}
        extra={
          <Flex wrap gap={token.paddingXS}>
            <Input
              autoFocus
              allowClear
              placeholder="Suche"
              value={search ?? ''}
              onChange={e => setSearch(e.target.value)}
              prefix={<SearchOutlined />}
              suffix={
                <Tooltip title="Suche nach Zuweiser Name, Zuweiser ME-Adresse, Zuweiser Kundennummer, Patient Vorname, Patient Nachname, Patient SVNR, oder Auftragsnummer">
                  <InfoCircleOutlined />
                </Tooltip>
              }
              className={css`
                width: 300px;
              `}
            />
          </Flex>
        }
      />
      <Row gutter={[16, 16]}>
        {Array.from(filteredData.entries()).map(entry => (
          <Col span={8} key={entry[0]}>
            <Card
              type="inner"
              size="small"
              hoverable
              title={
                <Markable tokens={debouncedSearch ?? ''}>
                  {entry[0]}{' '}
                  <Typography.Text type="secondary">
                    {entry[1][0].doctor.assignedLabs.map(it => it.customerId).join(', ')} {entry[1][0].doctor.meAddress}
                  </Typography.Text>
                </Markable>
              }
              extra={<Badge showZero count={entry[1].length} style={{ backgroundColor: token.colorPrimary }} />}
              className={css`
                height: 100%;
              `}
              onClick={() => {
                setCurrentDoctorId(entry[1][0].doctor.id);
                navigate('/offene-anforderungen');
              }}
            >
              <List
                size="small"
                className={css`
                  max-height: 130px;
                  overflow: auto;
                `}
                dataSource={entry[1].map(r => ({
                  patient: `${r.patientData.title} ${r.patientData.firstName} ${r.patientData.lastName}`,
                  metadata: `${r.patientData.svnr} ${r.orders.map(it => it.number).join(', ')}`,
                }))}
                renderItem={item => (
                  <List.Item>
                    <List.Item.Meta
                      title={<Markable tokens={debouncedSearch ?? ''}>{item.patient}</Markable>}
                      description={<Markable tokens={debouncedSearch ?? ''}>{item.metadata}</Markable>}
                    />
                  </List.Item>
                )}
              />
            </Card>
          </Col>
        ))}
      </Row>
    </MainContent>
  );
};
