import React, { useState } from 'react';
import { MainContent } from '../../../components/MainContent';
import { App, Button, Input, Radio, Space, Steps, theme, Typography } from 'antd';
import { PageHeader } from '@ant-design/pro-components';
import { css } from '@emotion/css';
import { openExternalLink } from '../../../utils/linkOpener.ts';
import { isValidIpAddress } from '../../../utils/ipAddressValidator.ts';
import { ApolloError, useApolloClient, useMutation } from '@apollo/client';
import { graphql } from '../../../graphql/generated';
import { CardReadersForIpQuery } from '../../../graphql/generated/graphql.ts';
import { useCurrentContextStore } from '../../../hooks/store/useCurrentContextStore.ts';
import { hasSomeRole, Role } from '../../../utils/user.ts';
import { useAuth } from 'react-oidc-context';
import { ConnectionState, EcardConnectionCheck } from './EcardConnectionCheck.tsx';

type CardReader = NonNullable<CardReadersForIpQuery['ecardCardReaders']>[number];

const CARD_READERS_FOR_IP_QUERY = graphql(`
  query CardReadersForIp($ipAddress: String!) {
    ecardCardReaders(ipAddress: $ipAddress) {
      id
      checkIp
      firmwareVersion
    }
  }
`);

const CREATE_ECARD_CONFIG_MUTATION = graphql(`
  mutation CreateEcardConfig($input: EcardConfigInput!) {
    createEcardConfig(input: $input) {
      id
    }
  }
`);

export const EcardSetupAssistant: React.FC<{ onHideAssistant: () => void }> = ({ onHideAssistant }) => {
  const { token } = theme.useToken();
  const [step, setStep] = useState(0);
  const [ipAddress, setIpAddress] = useState('');
  const [connectionState, setConnectionState] = useState<ConnectionState>('pending');
  const [cardReaders, setCardReaders] = useState<CardReader[]>([]);
  const [cardReadersError, setCardReadersError] = useState('');
  const [cardReadersLoading, setCardReadersLoading] = useState(false);
  const [cardReader, setCardReader] = useState<CardReader | null>(null);
  const [cardData, setCardData] = useState<string | null>(null);
  const apolloClient = useApolloClient();
  const { message } = App.useApp();
  const { currentDoctorId, primaryDoctorId } = useCurrentContextStore();
  const auth = useAuth();
  const isUserRole = hasSomeRole([Role.ROLE_LR_USER], auth.user);

  const [createEcardConfigMutation, { loading: createEcardConfigLoading }] = useMutation(CREATE_ECARD_CONFIG_MUTATION);

  const loadCardReaders = async () => {
    setCardReadersLoading(true);
    try {
      const { data, error } = await apolloClient.query({
        query: CARD_READERS_FOR_IP_QUERY,
        variables: {
          ipAddress: ipAddress,
        },
      });

      if (error) {
        setCardReadersError(error.message);
        return;
      }

      setCardReaders(data.ecardCardReaders ?? []);
      setStep(step => step + 1);
    } catch (e) {
      if (e instanceof ApolloError) {
        setCardReadersError(e.message);
      }
    } finally {
      setCardReadersLoading(false);
    }
  };

  const checkCardReaderConnection = () => {
    // TODO
    setStep(step => step + 1);
  };

  const readCardData = () => {
    // TODO
  };

  const createEcardConfig = async () => {
    try {
      await createEcardConfigMutation({
        variables: {
          input: {
            doctorId: isUserRole ? currentDoctorId : primaryDoctorId,
            cardPin: 'pin',
            ipAddress: '1.1.1.1',
            cardReaderAddress: cardReader?.checkIp ?? 'address',
            cardReaderName: cardReader?.id ?? 'name',
            ordinationId: 'TODO',
            ordinationName: 'Ordiname',
            taetigkeitsBereichId: 'TODO',
            taetigkeitsBereichName: 'Tätigkeit',
          },
        },
      });

      onHideAssistant();
    } catch {
      message.error('e-card Konfiguration konnte nicht angelegt werden');
    }
  };

  return (
    <MainContent size="medium">
      <PageHeader title="e-card Einrichtungsassistent" style={{ padding: 0, paddingBottom: 'inherit' }} />
      <Button onClick={createEcardConfig} loading={createEcardConfigLoading}>
        Create
      </Button>
      <Steps
        direction="vertical"
        current={step}
        className={css`
          .ant-steps-item-finish .ant-steps-item-icon {
            background-color: ${token.colorSuccess};
            border-color: ${token.colorSuccess};
          }

          .ant-steps-item-finish .ant-steps-item-icon .ant-steps-icon {
            color: white;
          }
        `}
        items={[
          {
            title: 'Verbindung zu GIN',
            description:
              step === 0 ? (
                <Space direction="vertical" size="middle" style={{ padding: '15px 0 15px 0', width: '100%' }}>
                  <EcardConnectionCheck
                    connectionState={connectionState}
                    onConnectionStateChanged={setConnectionState}
                  />
                  <Button disabled={connectionState !== 'ok'} type="primary" onClick={() => setStep(step => step + 1)}>
                    Weiter
                  </Button>
                </Space>
              ) : (
                <Typography.Text type="secondary">
                  Das Gesundheits-Informations-Netz (GIN) ist von Ihrem Standort erreichbar
                </Typography.Text>
              ),
          },
          {
            title: 'Anschluss IP-Adresse',
            description:
              step === 1 ? (
                <Space direction="vertical" size="middle" style={{ padding: '15px 0 15px 0' }}>
                  Tragen Sie bitte hier die IP-Adresse Ihres e-card Anschlusses ein:
                  <Input
                    placeholder="0.0.0.0"
                    value={ipAddress}
                    onChange={e => {
                      setIpAddress(e.target.value);
                      setCardReaders([]);
                      setCardReadersError('');
                      setCardReader(null);
                    }}
                    style={{ width: '300px', marginLeft: '2px' }}
                  />
                  <Button
                    disabled={!isValidIpAddress(ipAddress)}
                    type="primary"
                    onClick={() => loadCardReaders()}
                    loading={cardReadersLoading}
                  >
                    Weiter
                  </Button>
                  {cardReadersError && <Typography.Text type="danger">{cardReadersError}</Typography.Text>}
                  <Typography.Paragraph type="secondary" style={{ margin: 0 }}>
                    Sie finden diese IP-Addresse unter:
                    <ul style={{ margin: 0 }}>
                      <li>
                        <a onClick={() => openExternalLink('http://myip.ecard.sozialversicherung.at/')}>
                          http://myip.ecard.sozialversicherung.at/
                        </a>
                      </li>
                      <li>
                        oder{' '}
                        <a
                          onClick={() =>
                            openExternalLink(
                              'https://services.ecard.sozialversicherung.at/auth-gui/cardReaderOverview.xhtml'
                            )
                          }
                        >
                          https://services.ecard.sozialversicherung.at/auth-gui/cardReaderOverview.xhtml
                        </a>
                      </li>
                      <li>oder im Vertrag ihres GIN-Providers (A1, Drei, Magenta ...)</li>
                      <li>oder über eine Auskunft bei der SVC-Hotline</li>
                    </ul>
                  </Typography.Paragraph>
                </Space>
              ) : (
                step > 1 && (
                  <Typography.Text type="secondary">
                    Die Verbindung zum e-card Netz mit der IP-Adresse {ipAddress} wurde erfolgreich hergestellt
                  </Typography.Text>
                )
              ),
          },
          {
            title: 'Kartenlesegerät auswählen',
            description:
              step === 2 ? (
                <Space direction="vertical" size="middle" style={{ padding: '15px 0 15px 0' }}>
                  Bitte wählen Sie ein Kartenlesegerät (GINO) aus, in das Sie ihre Admin-Karte (o-card) stecken werden:
                  {cardReaders.length > 0 ? (
                    <Radio.Group
                      onChange={e => {
                        const cardReader = cardReaders.find(it => it.id === e.target.value);
                        if (cardReader) {
                          setCardReader(cardReader);
                        }
                      }}
                      value={cardReader?.id ?? ''}
                    >
                      <Space direction="vertical">
                        {cardReaders.map(cr => (
                          <Radio key={cr.id} value={cr.id}>
                            <strong>{cr.id}</strong>{' '}
                            <Typography.Text type="secondary">
                              IP: {cr.checkIp} Firmware: {cr.firmwareVersion}
                            </Typography.Text>
                          </Radio>
                        ))}
                      </Space>
                    </Radio.Group>
                  ) : (
                    <Typography.Text type="danger">
                      Es konnten keine Kartenlesegeräte für Ihren Anschluss gefunden werden
                    </Typography.Text>
                  )}
                  <Space>
                    <Button onClick={() => setStep(step => step - 1)}>Zurück</Button>
                    <Button disabled={!cardReader} type="primary" onClick={checkCardReaderConnection}>
                      Weiter
                    </Button>
                  </Space>
                </Space>
              ) : (
                step > 1 && (
                  <Typography.Text type="secondary">
                    Die Verbindung zum Lesegerät {cardReader?.id} wurde erfolgreich hergestellt
                  </Typography.Text>
                )
              ),
          },
          {
            title: 'Admin-Karte stecken',
            description:
              step === 3 ? (
                <Space direction="vertical" size="middle" style={{ padding: '15px 0 15px 0' }}>
                  <div>
                    Bitte stecken Sie jetzt Ihre Admin-Karte in das Kartenlesegerät <strong>{cardReader?.id}</strong>{' '}
                    und klicken Sie auf "Kartendaten auslesen":
                  </div>
                  <Button type="primary" onClick={readCardData}>
                    Kartendaten auslesen
                  </Button>
                  <Space>
                    <Button onClick={() => setStep(step => step - 1)}>Zurück</Button>
                    <Button disabled={!cardData} type="primary" onClick={() => {}}>
                      Weiter
                    </Button>
                  </Space>
                </Space>
              ) : (
                step > 1 && (
                  <Typography.Text type="secondary">
                    Die Verbindung zum Lesegerät xxx wurde erfolgreich hergestellt
                  </Typography.Text>
                )
              ),
          },
          {
            title: 'Admin-PIN angeben',
            description:
              step === 4 ? (
                <Space direction="vertical" size="middle" style={{ padding: '15px 0 15px 0' }}>
                  Bitte stecken Sie jetzt Ihre Admin-Karte in das Kartenlesegerät xxx und klicken Sie auf "Kartendaten
                  auslesen":
                  <Button type="primary" onClick={() => setCardData('todo')}>
                    Kartendaten auslesen
                  </Button>
                  <Space>
                    <Button onClick={() => setStep(step => step - 1)}>Zurück</Button>
                    <Button disabled={!cardData} type="primary" onClick={() => setStep(step => step + 1)}>
                      Weiter
                    </Button>
                  </Space>
                </Space>
              ) : (
                step > 1 && (
                  <Typography.Text type="secondary">
                    Die Verbindung zum Lesegerät xxx wurde erfolgreich hergestellt
                  </Typography.Text>
                )
              ),
          },
        ]}
      />
    </MainContent>
  );
};
