import React, { useEffect, useState } from 'react';
import { Button, Input, Table, Tooltip, Typography } from 'antd';
import { MainContent } from '../../components/MainContent';
import { useQuery } from '@apollo/client';
import { ColumnsType } from 'antd/es/table';
import { useDebounce } from 'use-debounce';
import { Markable } from '../../components/Markable';
import {
  EditOutlined,
  EyeOutlined,
  InfoCircleOutlined,
  PlusCircleOutlined,
  SearchOutlined,
  UndoOutlined,
} from '@ant-design/icons';
import { PageHeader } from '@ant-design/pro-components';
import { tableActionCell } from '../../styles/globalCss';
import { CreateThemeModal } from './themes/CreateThemeModal.tsx';
import { UpdateThemeModal } from './themes/UpdateThemeModal.tsx';
import { css } from '@emotion/css';
import { defaultTheme, useThemeStore } from '../../hooks/store/useThemeStore.ts';
import { useDoctorTheme } from '../../hooks/useDoctorTheme.ts';
import { graphql } from '../../graphql/generated';
import { ThemesQuery } from '../../graphql/generated/graphql.ts';
import { useCurrentContextStore } from '../../hooks/store/useCurrentContextStore.ts';

const THEMES_QUERY = graphql(`
  query Themes {
    themes {
      id
      name
      colorPrimary
      controlItemBgActive
      controlItemBgActiveHover
      colorBgHeader
      colorBgTrigger
      logoData
    }
  }
`);

const ColorInfo: React.FC<{ color: string }> = ({ color }) => {
  return (
    <div
      className={css`
        display: flex;
      `}
    >
      <div
        className={css`
          width: 16px;
          height: 16px;
          background-color: ${color};
          border-radius: 999px;
        `}
      />
      <Typography.Text
        type="secondary"
        className={css`
          font-size: 0.9em;
          margin-left: 5px;
        `}
      >
        {color}
      </Typography.Text>
    </div>
  );
};

export type Theme = NonNullable<ThemesQuery['themes']>[number];

export const Themes: React.FC = () => {
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [editTheme, setEditTheme] = useState<Theme | null>(null);
  const [search, setSearch] = useState<string | null>(null);
  const [debouncedSearch] = useDebounce(search, 250);
  const [filteredThemes, setFilteredThemes] = useState<Theme[]>([]);
  const { setTheme, previewMode } = useThemeStore();
  const { currentDoctorId } = useCurrentContextStore();
  const doctorTheme = useDoctorTheme(currentDoctorId);

  const { data, loading, refetch } = useQuery(THEMES_QUERY, {
    pollInterval: 60000,
  });

  useEffect(() => {
    const result =
      data?.themes?.filter(theme => {
        if (debouncedSearch && debouncedSearch.length) {
          const searchValue = debouncedSearch.toLowerCase();
          return theme.name.toLowerCase().includes(searchValue);
        }
        return true;
      }) ?? [];
    setFilteredThemes(result);
  }, [debouncedSearch, data]);

  const columns: ColumnsType<Theme> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      width: 150,
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: value => <Markable tokens={debouncedSearch ?? ''}>{value}</Markable>,
    },
    {
      title: 'Primärfarbe',
      dataIndex: 'colorPrimary',
      key: 'colorPrimary',
      ellipsis: true,
      sorter: (a, b) => a.colorPrimary.localeCompare(b.colorPrimary),
      render: value => <ColorInfo color={value} />,
    },
    {
      title: 'BG Aktiv',
      dataIndex: 'controlItemBgActive',
      key: 'controlItemBgActive',
      ellipsis: true,
      sorter: (a, b) => a.controlItemBgActive.localeCompare(b.controlItemBgActive),
      render: value => <ColorInfo color={value} />,
    },
    {
      title: 'BG Hover',
      dataIndex: 'controlItemBgActiveHover',
      key: 'controlItemBgActiveHover',
      ellipsis: true,
      sorter: (a, b) => a.controlItemBgActiveHover.localeCompare(b.controlItemBgActiveHover),
      render: value => <ColorInfo color={value} />,
    },
    {
      title: 'BG Header',
      dataIndex: 'colorBgHeader',
      key: 'colorBgHeader',
      ellipsis: true,
      sorter: (a, b) => a.colorBgHeader.localeCompare(b.colorBgHeader),
      render: value => <ColorInfo color={value} />,
    },
    {
      title: 'BG Trigger',
      dataIndex: 'colorBgTrigger',
      key: 'colorBgTrigger',
      ellipsis: true,
      sorter: (a, b) => a.colorBgTrigger.localeCompare(b.colorBgTrigger),
      render: value => <ColorInfo color={value} />,
    },
    {
      title: 'Logo',
      dataIndex: 'logoData',
      key: 'logoData',
      ellipsis: true,
      width: 150,
      render: value =>
        value ? (
          <img
            src={value}
            alt="Logo Preview"
            className={css`
              height: 20px;
            `}
          />
        ) : (
          ''
        ),
    },
    {
      title: '',
      key: 'actions',
      fixed: 'right',
      align: 'right',
      ellipsis: true,
      width: '50px',
      className: tableActionCell,
      render: (_, record) => (
        <>
          <Button icon={<EditOutlined />} type="text" onClick={() => setEditTheme(record)} />
          <Button icon={<EyeOutlined />} type="text" onClick={() => setTheme(record, true)} />
        </>
      ),
    },
  ];

  return (
    <MainContent>
      <PageHeader
        title="Themes"
        extra={[
          <Button
            key="reset-theme"
            hidden={!previewMode}
            onClick={() => setTheme(doctorTheme ?? defaultTheme, false)}
            icon={<UndoOutlined />}
          >
            Theme zurücksetzen
          </Button>,
          <Input
            key="search"
            allowClear
            autoFocus
            placeholder="Suche"
            value={search ?? ''}
            onChange={e => setSearch(e.target.value)}
            prefix={<SearchOutlined />}
            suffix={
              <Tooltip title="Suche nach Name">
                <InfoCircleOutlined />
              </Tooltip>
            }
            style={{ width: '250px' }}
          />,
          <Button key="create" icon={<PlusCircleOutlined />} type="primary" onClick={() => setCreateModalOpen(true)}>
            Neues Theme
          </Button>,
        ]}
        style={{ padding: 0, paddingBottom: 'inherit' }}
      />
      <Table<Theme>
        scroll={{ x: 'max-content' }}
        rowKey={record => record.id}
        sticky={true}
        size="middle"
        showSorterTooltip={false}
        dataSource={filteredThemes}
        pagination={{
          showQuickJumper: true,
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]} bis ${range[1]} von ${total} Themes`,
        }}
        loading={loading}
        columns={columns}
      />
      <CreateThemeModal
        open={createModalOpen}
        onClose={() => {
          setCreateModalOpen(false);
          refetch();
        }}
      />
      <UpdateThemeModal
        theme={editTheme}
        onClose={() => {
          setEditTheme(null);
          refetch();
        }}
      />
    </MainContent>
  );
};
