import React, { useCallback, useEffect, useState } from 'react';
import { App, Badge, Button, Dropdown, Flex, Input, Modal, Popconfirm, Table, theme, Tooltip, Typography } from 'antd';
import { MainContent } from '../components/MainContent';
import { useMutation, useQuery } from '@apollo/client';
import { useDebounce } from 'use-debounce';
import { ColumnsType, ColumnType } from 'antd/es/table';
import { Markable } from '../components/Markable';
import {
  CloudUploadOutlined,
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  EyeOutlined,
  FileTextOutlined,
  InfoCircleOutlined,
  LockOutlined,
  PrinterOutlined,
  SearchOutlined,
  SendOutlined,
  WarningTwoTone,
} from '@ant-design/icons';
import { SHORT_DATE_FORMAT, SHORT_DATETIME_FORMAT } from '../utils/dateFormatUtils';
import { RequestStatusBadge } from '../components/RequestStatusBadge';
import { RequestDetailsModal } from './request/RequestDetailsModal';
import { TableColumnSettings } from '../components/TableColumnSettings';
import { useNavigate } from 'react-router';
import { useRequestCopy } from '../hooks/useRequestCopy';
import { ItemType } from 'antd/es/menu/interface';
import { showParamWarning } from '../components/showParamWarning';
import { usePrintRequest } from '../hooks/usePrintRequest';
import { useSendRequests } from '../hooks/useSendRequests';
import { PageHeader } from '@ant-design/pro-components';
import dayjs from 'dayjs';
import { tableActionCell, tableHoverPointer } from '../styles/globalCss';
import { usePrintEmptyLabel } from '../hooks/usePrintEmptyLabel';
import { useAppStore } from '../hooks/store/useAppStore';
import { useAisExportRequest } from '../hooks/useAisExportRequest';
import { Forms } from './forms/Forms.tsx';
import { hasFrontDeskRole, hasSomeRole, Role } from '../utils/user';
import { graphql } from '../graphql/generated';
import { OpenRequestsQuery, PatientData, RequestStatus } from '../graphql/generated/graphql.ts';
import { useAuth } from 'react-oidc-context';
import { useSubscription } from '../socket/useSubscription.ts';
import { UpdateEvent } from '../socket/types.ts';
import { useCurrentContextStore } from '../hooks/store/useCurrentContextStore.ts';
import { css } from '@emotion/css';
import { TableColumnSetting } from '../hooks/store/useUserSettingsStore.ts';

export const OPEN_REQUESTS_QUERY = graphql(`
  query OpenRequests($currentDoctorId: ID!, $doctorId: ID!) {
    openRequests(doctorId: $currentDoctorId, svnr: null) {
      id
      createdAt
      modifiedAt
      sampledAt
      status
      orders {
        id
        number
        lab {
          id
          shortName
        }
        orderLabels {
          id
          printedAt
          specimenId
          testTubeId
          location
        }
        orderFiles {
          id
          printable
          printRequired
          printBeforeSend
          printCopies
          printedAt
        }
      }
      patientData {
        birthday
        city
        country
        email
        externalId
        firstName
        lastName
        gender
        zip
        svnr
        title
        street
        phone
        insuranceCode
        idCardAuthority
        idCardNumber
        idCardType
        insuranceCategory
        insuredPerson {
          phone
          street
          title
          svnr
          zip
          birthday
          gender
          firstName
          email
          country
          city
          lastName
        }
      }
      createdByFirstName
      createdByLastName
      modifiedByFirstName
      modifiedByLastName
    }

    doctor(id: $doctorId) {
      id
      flipParamNames
      addEmptyLabels
      csvImportEnabled
    }
  }
`);

const DELETE_ALL_OPEN_REQUESTS_MUTATION = graphql(`
  mutation DeleteAllOpenRequests($doctorId: ID!) {
    deleteAllOpenRequests(doctorId: $doctorId)
  }
`);

const DELETE_OPEN_REQUEST_MUTATION = graphql(`
  mutation DeleteOpenRequest($requestId: ID!) {
    deleteOpenRequest(requestId: $requestId)
  }
`);

export type Request = NonNullable<OpenRequestsQuery['openRequests']>[number];

export const OpenRequests: React.FC = () => {
  const auth = useAuth();
  const { currentDoctorId, primaryDoctorId } = useCurrentContextStore();
  const { notification, message, modal } = App.useApp();
  const { token } = theme.useToken();
  const setRequestIdForUpdate = useAppStore(state => state.setRequestIdForUpdate);
  const setExecuteRequestCopy = useAppStore(state => state.setExecuteRequestCopy);
  const navigate = useNavigate();
  const print = usePrintRequest();
  const aisExport = useAisExportRequest();
  const printEmptyLabel = usePrintEmptyLabel();
  const send = useSendRequests();
  const [search, setSearch] = useState<string | null>(null);
  const [debouncedSearch] = useDebounce(search, 250);
  const [filteredRequests, setFilteredRequests] = useState<Request[]>([]);
  const [selectedRequests, setSelectedRequests] = useState<Request[]>([]);
  const [deleteRequest, setDeleteRequest] = useState<Request | null>(null);
  const [detailsRequestId, setDetailsRequestId] = useState<string | null>(null);
  const [formsPatientData, setFormsPatientData] = useState<PatientData | null>(null);

  const deletedAllowed = hasSomeRole(
    [Role.ROLE_LR_MEDCOM, Role.ROLE_LR_USER, Role.ROLE_LR_LAB_ADMIN, Role.ROLE_LR_FRONT_DESK],
    auth.user
  );
  const editAllowed = hasSomeRole(
    [Role.ROLE_LR_MEDCOM, Role.ROLE_LR_USER, Role.ROLE_LR_LAB_ADMIN, Role.ROLE_LR_FRONT_DESK],
    auth.user
  );
  const isFrontDesk = hasFrontDeskRole(auth.user);

  const { data, loading, refetch } = useQuery(OPEN_REQUESTS_QUERY, {
    variables: {
      currentDoctorId: currentDoctorId,
      doctorId: isFrontDesk ? primaryDoctorId : currentDoctorId,
    },
  });

  const callback = useCallback(
    (event: UpdateEvent) => {
      console.debug('received UI update: ' + JSON.stringify(event));
      refetch();
    },
    [refetch]
  );

  useSubscription({ type: 'request-created', tenantId: currentDoctorId }, callback);
  useSubscription({ type: 'request-updated', tenantId: currentDoctorId }, callback);
  useSubscription({ type: 'request-deleted', tenantId: currentDoctorId }, callback);
  useSubscription({ type: 'request-printed', tenantId: currentDoctorId }, callback);
  useSubscription({ type: 'request-sent', tenantId: currentDoctorId }, callback);

  useRequestCopy('update', true, null, copyData => {
    if (copyData.unknownBillingParameters.length) {
      showParamWarning(
        notification,
        'Verrechnungssituation geändert',
        'Für folgende Parameter hat sich die Verrechnungssituation geändert:',
        copyData.unknownBillingParameters.map(it => (data?.doctor?.flipParamNames ? it.longName : it.shortName))
      );
    }
    if (copyData.unavailableParameters.length) {
      showParamWarning(
        notification,
        'Parameter nicht verfügbar',
        'Folgende Parameter stehen nicht zur Verfügung:',
        copyData.unavailableParameters
      );
    }
    navigate('/anforderung/parameterauswahl');
  });

  const printWithFeedback = (request: Request) => {
    print(
      'before-send',
      request,
      () => message.error('Beim Drucken ist ein Fehler aufgetreten'),
      () =>
        message.info(
          <>
            Es sind bereits alle erforderlichen Etiketten und Dokumente für diese Anforderung gedruckt worden. Zum
            erneuten Drucken öffnen Sie bitte die{' '}
            <Button type="link" onClick={() => setDetailsRequestId(request.id)}>
              Anforderungsdetails
            </Button>
          </>
        ),
      () => message.success('Etiketten & Dokumente wurden an den Drucker gesendet'),
      () => message.error('Anforderungsstatus kann nicht gesetzt werden'),
      () => refetch()
    );

    if (request.status === RequestStatus.NEW && data?.doctor?.addEmptyLabels) {
      printEmptyLabel(
        {
          doctorId: currentDoctorId,
          patientTitle: request.patientData.title,
          patientLastName: request.patientData.lastName,
          patientFirstName: request.patientData.firstName,
          patientSvnr: request.patientData.svnr,
        },
        data.doctor.addEmptyLabels,
        () => message.error('Beim Drucken der Leeretiketten ist ein Fehler aufgetreten'),
        () => {} // silently ignore
      );
    }
  };

  const [deleteAllOpenRequestsMutation] = useMutation(DELETE_ALL_OPEN_REQUESTS_MUTATION);
  const [deleteOpenRequestMutation] = useMutation(DELETE_OPEN_REQUEST_MUTATION);

  const [columnSettings, setColumnSettings] = useState<TableColumnSetting[]>([
    { id: 'status', visible: true, title: 'Status', checkable: false },
    { id: 'patientData.lastName', visible: true, title: 'Name', checkable: true },
    { id: 'createdAt', visible: true, title: 'Erstellt am', checkable: false },
    { id: 'patientData.svnr', visible: true, title: 'SVNR', checkable: true },
    { id: 'patientData.birthday', visible: true, title: 'Geburtstag', checkable: true },
    { id: 'sampledAt', visible: false, title: 'Probenentnahme am', checkable: true },
    { id: 'orders', visible: true, title: 'Auftrag', checkable: true },
    { id: 'creator', visible: false, title: 'Erstellt von', checkable: true },
    { id: 'modifier', visible: false, title: 'Bearbeitet von', checkable: true },
    { id: 'modifiedAt', visible: false, title: 'Bearbeitet am', checkable: true },
  ]);

  const columns: ColumnsType<Request> = [
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 100,
      ellipsis: true,
      sorter: (a, b) => a.status.localeCompare(b.status),
      render: (value, record) => (
        <>
          <RequestStatusBadge status={value} />{' '}
          {record.status === RequestStatus.NEW && (
            <Button
              size="small"
              type="link"
              icon={<PrinterOutlined />}
              disabled={!window.nativeApi}
              onClick={e => {
                e.stopPropagation();
                printWithFeedback(record);
              }}
            >
              Drucken
            </Button>
          )}
          {record.status === RequestStatus.PRINTED && (
            <Button
              size="small"
              type="link"
              icon={<SendOutlined />}
              onClick={e => {
                e.stopPropagation();
                sendRequest(record.id);
              }}
            >
              Senden
            </Button>
          )}
        </>
      ),
    },
    {
      title: 'Name',
      dataIndex: ['patientData', 'lastName'],
      key: 'patientData.lastName',
      width: 100,
      ellipsis: true,
      sorter: (a, b) => a.patientData.lastName.localeCompare(b.patientData.lastName),
      render: (_, record) => (
        <Typography.Text style={{ fontWeight: 500 }}>
          {record.patientData.title}{' '}
          <Markable tokens={debouncedSearch ?? ''}>
            {record.patientData.firstName} {record.patientData.lastName}
          </Markable>
        </Typography.Text>
      ),
    },
    {
      title: 'Erstellt am',
      dataIndex: 'createdAt',
      key: 'createdAt',
      defaultSortOrder: 'descend',
      width: 100,
      ellipsis: true,
      sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
      render: value => dayjs(value).format(SHORT_DATETIME_FORMAT),
    },
    {
      title: 'SVNR',
      dataIndex: ['patientData', 'svnr'],
      key: 'patientData.svnr',
      width: 70,
      ellipsis: true,
      sorter: (a, b) => a.patientData.svnr.localeCompare(b.patientData.svnr),
      render: value => <Markable tokens={debouncedSearch ?? ''}>{value}</Markable>,
    },
    {
      title: 'Geburtstag',
      dataIndex: ['patientData', 'birthday'],
      key: 'patientData.birthday',
      width: 120,
      ellipsis: true,
      sorter: (a, b) => a.patientData.birthday.localeCompare(b.patientData.birthday),
      render: value => dayjs(value).format(SHORT_DATE_FORMAT),
    },
    {
      title: 'Probenentnahme am',
      dataIndex: 'sampledAt',
      key: 'sampledAt',
      sorter: (a, b) => (a.sampledAt ?? '').localeCompare(b.sampledAt ?? ''),
      width: 180,
      ellipsis: true,
      render: value => (value ? dayjs(value).format(SHORT_DATETIME_FORMAT) : ''),
    },
    {
      title: 'Auftrag',
      dataIndex: 'orders',
      key: 'orders',
      width: 100,
      ellipsis: true,
      render: (_, record) =>
        [...record.orders]
          .sort((a, b) => a.lab.id.localeCompare(b.lab.id))
          .map((order, index, orders) => (
            <span key={order.id}>
              <Markable tokens={debouncedSearch ?? ''}>{order.number}</Markable>{' '}
              <Typography.Text type="secondary">{order.lab.shortName}</Typography.Text>
              {orders.length - 1 > index ? ' - ' : ''}
            </span>
          )),
    },
    {
      title: 'Erstellt von',
      key: 'creator',
      width: 110,
      ellipsis: true,
      sorter: (a, b) => a.createdByLastName.localeCompare(b.createdByLastName),
      render: (_, record) => record.createdByFirstName + ' ' + record.createdByLastName,
    },
    {
      title: 'Bearbeitet von',
      key: 'modifier',
      width: 140,
      ellipsis: true,
      sorter: (a, b) => a.modifiedByLastName.localeCompare(b.modifiedByLastName),
      render: (_, record) => record.modifiedByFirstName + ' ' + record.modifiedByLastName,
    },
    {
      title: 'Bearbeitet am',
      dataIndex: 'modifiedAt',
      key: 'modifiedAt',
      width: 130,
      ellipsis: true,
      sorter: (a, b) => {
        if (!a.modifiedAt && !b.modifiedAt) return 0;
        if (a.modifiedAt && !b.modifiedAt) return 1;
        if (!a.modifiedAt && b.modifiedAt) return 0;
        return a.modifiedAt!.localeCompare(b.modifiedAt!);
      },
      render: value => (value ? dayjs(value).format(SHORT_DATETIME_FORMAT) : null),
    },
  ];

  const actionsColumn: ColumnType<Request> = {
    title: '',
    key: 'actions',
    fixed: 'right',
    align: 'right',
    ellipsis: true,
    width: '100px',
    className: tableActionCell,
    render: (_, record) => {
      const items: ItemType[] = [];
      if (window.nativeApi) {
        items.push(
          {
            label: 'Drucken',
            key: 'print',
            icon: <PrinterOutlined />,
            onClick: info => {
              info.domEvent.stopPropagation();
              printWithFeedback(record);
            },
          },
          {
            label: 'Leer-Etikett drucken',
            key: 'empty-label',
            icon: <PrinterOutlined />,
            onClick: info => {
              info.domEvent.stopPropagation();
              printEmptyLabel(
                {
                  doctorId: currentDoctorId,
                  patientTitle: record.patientData.title,
                  patientLastName: record.patientData.lastName,
                  patientFirstName: record.patientData.firstName,
                  patientSvnr: record.patientData.svnr,
                },
                1,
                () => message.error('Beim Drucken ist ein Fehler aufgetreten'),
                () => message.success('Leer-Etikett wurde an den Drucker gesendet')
              );
            },
          }
        );
      }
      items.push({
        label: 'Formulare',
        key: 'forms',
        icon: <FileTextOutlined />,
        onClick: info => {
          info.domEvent.stopPropagation();
          setFormsPatientData(record.patientData);
        },
      });
      if (editAllowed) {
        items.push({
          label: 'Bearbeiten',
          key: 'edit',
          icon: <EditOutlined />,
          onClick: info => {
            info.domEvent.stopPropagation();
            setExecuteRequestCopy(true);
            setRequestIdForUpdate(record.id);
          },
        });
      }
      if (deletedAllowed) {
        items.push({
          label: 'Löschen',
          danger: true,
          key: 'delete',
          icon: <DeleteOutlined />,
          onClick: info => {
            info.domEvent.stopPropagation();
            setDeleteRequest(record);
          },
        });
      }

      return (
        <>
          <Button
            type="link"
            icon={<EyeOutlined />}
            onClick={e => {
              e.stopPropagation();
              setDetailsRequestId(record.id);
            }}
          >
            Details
          </Button>
          <Dropdown menu={{ items: items }} trigger={['click']} placement="bottomRight">
            <Button
              icon={<EllipsisOutlined style={{ fontSize: '20px', verticalAlign: 'middle' }} />}
              type="text"
              onClick={e => e.stopPropagation()}
            />
          </Dropdown>
        </>
      );
    },
  };

  const filteredColumns: ColumnsType<Request> = [
    ...columnSettings
      .filter(cs => cs.visible)
      .map(cs => {
        return columns.find(c => c.key === cs.id)!;
      }),
    actionsColumn,
  ];

  useEffect(() => {
    const searchTokens =
      debouncedSearch
        ?.toLowerCase()
        .split(' ')
        .filter(it => it !== '') ?? [];

    const filtered = data?.openRequests?.filter(r => {
      if (searchTokens.length) {
        return searchTokens.every(token => {
          return (
            r.patientData.firstName.toLowerCase().includes(token) ||
            r.patientData.lastName.toLowerCase().includes(token) ||
            r.patientData.svnr.toLowerCase().includes(token) ||
            r.orders.map(order => order.number.toLowerCase()).some(it => it.includes(token))
          );
        });
      }
      return true;
    });
    setFilteredRequests(filtered ?? []);
  }, [debouncedSearch, data]);

  const deleteAllOpenRequests = async () => {
    try {
      await deleteAllOpenRequestsMutation({
        variables: {
          doctorId: currentDoctorId,
        },
      });

      message.success('Alle offenen Anforderungen wurden gelöscht');
    } catch {
      message.error('Offene Anforderungen konnten nicht gelöscht werden');
    } finally {
      setSelectedRequests([]);
    }
  };

  const deleteOpenRequest = async (requestId: string | undefined | null) => {
    if (!requestId) {
      return;
    }

    try {
      await deleteOpenRequestMutation({
        variables: {
          requestId: requestId,
        },
      });

      message.success('Anforderung wurde gelöscht');
    } catch {
      message.error('Anforderung konnte nicht gelöscht werden');
    } finally {
      setDeleteRequest(null);
      setSelectedRequests([...selectedRequests.filter(it => it.id !== requestId)]);
    }
  };

  const sendRequests = async () => {
    const emptyLabelsCount = data?.doctor?.addEmptyLabels ?? 0;
    if (emptyLabelsCount) {
      selectedRequests
        .filter(it => it.status === RequestStatus.NEW)
        .forEach(it => {
          printEmptyLabel(
            {
              doctorId: currentDoctorId,
              patientTitle: it.patientData.title,
              patientLastName: it.patientData.lastName,
              patientFirstName: it.patientData.firstName,
              patientSvnr: it.patientData.svnr,
            },
            emptyLabelsCount,
            () =>
              message.error(
                `Beim Drucken der Leeretiketten für ${it.patientData.title} ${it.patientData.firstName} ${it.patientData.lastName} ist ein Fehler aufgetreten`
              ),
            () => {} // silently ignore
          );
        });
    }

    try {
      const response = await send(selectedRequests.map(it => it.id));
      for (const request of response.data!.sendRequests!) {
        await print(
          'send',
          request,
          () => message.error('Beim Drucken ist ein Fehler aufgetreten'),
          () => {},
          () => {},
          () => {},
          () => {}
        );
        await aisExport(
          request,
          msg => message.error('Beim AIS-Export ist ein Fehler aufgetreten: ' + msg),
          () => {}
        );
      }
      message.success('Anforderungen wurden gesendet');
    } catch {
      message.error('Anforderungen konnten nicht gesendet werden');
    } finally {
      setSelectedRequests([]);
    }
  };

  const sendRequest = async (requestId: string) => {
    try {
      const response = await send([requestId]);
      await print(
        'send',
        response.data!.sendRequests![0],
        () => message.error('Beim Drucken ist ein Fehler aufgetreten'),
        () => {},
        () => {},
        () => {},
        () => {}
      );
      await aisExport(
        response.data!.sendRequests![0],
        () => message.error('Beim AIS-Export ist ein Fehler aufgetreten'),
        () => {}
      );
      message.success('Anforderung wurde gesendet');
    } catch {
      message.error('Anforderung konnte nicht gesendet werden');
    } finally {
      setSelectedRequests(selectedRequests.filter(it => it.id !== requestId));
    }
  };

  return (
    <MainContent>
      <PageHeader
        title="Offene Anforderungen"
        style={{ padding: 0, paddingBottom: 'inherit' }}
        extra={
          <Flex wrap gap={token.paddingXS}>
            <Input
              autoFocus
              allowClear
              placeholder="Suche"
              value={search ?? ''}
              onChange={e => setSearch(e.target.value)}
              prefix={<SearchOutlined />}
              suffix={
                <Tooltip title="Suche nach Vorname, Nachname, Auftragsnummer oder SVNR">
                  <InfoCircleOutlined />
                </Tooltip>
              }
              className={css`
                width: 200px;
                @media (min-width: 1450px) {
                  width: 300px;
                }
              `}
            />
            <Button
              icon={data?.doctor?.csvImportEnabled ? <CloudUploadOutlined /> : <LockOutlined />}
              onClick={() =>
                data?.doctor?.csvImportEnabled
                  ? navigate('/offene-anforderungen/csv-import')
                  : modal.warning({
                      icon: <LockOutlined />,
                      title: 'CSV-Import',
                      width: 500,
                      content: (
                        <>
                          <p>
                            Mit dem CSV-Import können Patienten- und Anforderungsdaten mittels CSV-Datei hochgeladen und
                            automatisch zu Anforderungen verarbeitet werden. Dies ist z.B. hilfreich für
                            Massenverarbeitungen.
                          </p>
                          <p>Bitte kontaktieren Sie Ihr Labor bei Interesse an dieser Funktion.</p>
                        </>
                      ),
                    })
              }
            >
              CSV-Import
            </Button>
            <Badge count={selectedRequests.length} offset={[-5, 0]}>
              <Button
                type="primary"
                icon={<SendOutlined />}
                disabled={selectedRequests.length <= 0}
                onClick={sendRequests}
              >
                Anforderungen senden
              </Button>
            </Badge>
            {deletedAllowed && (
              <Popconfirm
                title="Alle offenen Anforderungen löschen?"
                onConfirm={deleteAllOpenRequests}
                okText="Ja, alle löschen"
                okButtonProps={{ danger: true }}
                cancelText="Nein"
                placement="left"
                disabled={!data?.openRequests?.length}
              >
                <Button danger icon={<DeleteOutlined />} disabled={!data?.openRequests?.length}>
                  Alle löschen
                </Button>
              </Popconfirm>
            )}
            <TableColumnSettings
              tableKey="openRequests"
              initialColumnSettings={columnSettings}
              onChange={setColumnSettings}
            />
          </Flex>
        }
        className={css`
          .ant-page-header-heading {
            flex-wrap: wrap;
          }
        `}
      />
      <Table<Request>
        scroll={{ x: 'max-content' }}
        sticky
        showSorterTooltip={false}
        rowKey={record => record.id}
        size="middle"
        dataSource={filteredRequests}
        pagination={{
          showQuickJumper: true,
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]} bis ${range[1]} von ${total} offenen Anforderungen`,
        }}
        loading={loading}
        columns={filteredColumns}
        rowSelection={{
          columnWidth: 24,
          type: 'checkbox',
          selectedRowKeys: selectedRequests.map(it => it.id),
          onChange: (_, selectedRows) => setSelectedRequests(selectedRows),
        }}
        onRow={record => ({
          onClick: () => {
            if (selectedRequests.map(it => it.id).includes(record.id)) {
              setSelectedRequests(selectedRequests.filter(it => it.id !== record.id));
              return;
            }

            setSelectedRequests([...selectedRequests, record]);
          },
        })}
        rowClassName={tableHoverPointer}
      />
      <Modal
        title={
          <>
            <WarningTwoTone twoToneColor="#f5222d" /* red-6 */ /> Anforderung löschen
          </>
        }
        open={!!deleteRequest}
        okButtonProps={{ danger: true, icon: <DeleteOutlined /> }}
        okText="Anforderung löschen"
        onOk={() => deleteOpenRequest(deleteRequest?.id)}
        onCancel={() => setDeleteRequest(null)}
      >
        Wollen Sie die ausgewählte offene Anforderung wirklich löschen?
      </Modal>
      <RequestDetailsModal requestId={detailsRequestId} onClose={() => setDetailsRequestId(null)} />
      <Modal
        title={`Formulare für ${formsPatientData?.firstName} ${formsPatientData?.lastName}`}
        width={768}
        open={!!formsPatientData}
        footer={null}
        onCancel={() => setFormsPatientData(null)}
        destroyOnClose
      >
        {formsPatientData && <Forms patientData={formsPatientData} />}
      </Modal>
    </MainContent>
  );
};
