import React, { useEffect } from 'react';
import { MainContent } from '../../components/MainContent';
import { PageHeader } from '@ant-design/pro-components';
import { useCurrentContextStore } from '../../hooks/store/useCurrentContextStore.ts';
import { App, Button, Checkbox, Divider, Form, Input, InputNumber, Select, Typography } from 'antd';
import { BillingPreference, DoctorSalutation, DoctorSettingsInput } from '../../graphql/generated/graphql.ts';
import { translateBillingPreference, translateDoctorSalutation } from '../../utils/enumHelpers.ts';
import { serverSideEmailRule } from '../../utils/helpers.ts';
import { MailOutlined, PhoneOutlined, SaveOutlined } from '@ant-design/icons';
import { graphql } from '../../graphql/generated';
import { useMutation, useQuery } from '@apollo/client';
import { LoadingIndicator } from '../../components/LoadingIndicator.tsx';
import { useForm } from 'antd/es/form/Form';

const { Option } = Select;

const DOCTOR_SETTINGS_QUERY = graphql(`
  query DoctorSettings($doctorId: ID!) {
    doctor(id: $doctorId) {
      id
      salutation
      preTitle
      postTitle
      firstName
      lastName
      name
      specialist
      street
      zip
      city
      email
      phone
      billingPreference
      autoDiagnose
      addEmptyLabels
      printTransferDocument
    }
  }
`);

const UPDATE_DOCTOR_SETTINGS_MUTATION = graphql(`
  mutation UpdateDoctorSettings($id: ID!, $input: DoctorSettingsInput!) {
    updateDoctorSettings(id: $id, input: $input) {
      id
    }
  }
`);

export const Doctor: React.FC = () => {
  const { currentDoctorId } = useCurrentContextStore();
  const [form] = useForm<DoctorSettingsInput>();
  const { message } = App.useApp();

  const { data, loading, refetch } = useQuery(DOCTOR_SETTINGS_QUERY, {
    fetchPolicy: 'cache-and-network', // cache here to have the header info updated automatically when editing the current doctor
    variables: {
      doctorId: currentDoctorId,
    },
  });
  const doctor = data?.doctor;

  const [updateDoctorSettingsMutation, { loading: saveLoading }] = useMutation(UPDATE_DOCTOR_SETTINGS_MUTATION);

  useEffect(() => {
    form.setFieldsValue({
      salutation: doctor?.salutation ?? DoctorSalutation.HERR,
      name: doctor?.name ?? '',
      specialist: doctor?.specialist ?? '',
      firstName: doctor?.firstName ?? '',
      lastName: doctor?.lastName ?? '',
      preTitle: doctor?.preTitle ?? '',
      postTitle: doctor?.postTitle ?? '',
      street: doctor?.street ?? '',
      zip: doctor?.zip ?? '',
      city: doctor?.city ?? '',
      email: doctor?.email ?? '',
      phone: doctor?.phone ?? '',
      billingPreference: doctor?.billingPreference ?? BillingPreference.ASK,
      autoDiagnose: doctor?.autoDiagnose ?? false,
      addEmptyLabels: doctor?.addEmptyLabels ?? 0,
      printTransferDocument: doctor?.printTransferDocument ?? false,
    });
  }, [form, doctor]);

  const onFinish = async (values: DoctorSettingsInput) => {
    try {
      await updateDoctorSettingsMutation({
        variables: {
          id: doctor?.id ?? '',
          input: values,
        },
      });

      refetch();
      message.success('Zuweiser wurde aktualisiert');
    } catch (e) {
      message.error('Zuweiser konnte nicht aktualisiert werden: ' + e);
    }
  };

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <MainContent size="small">
      <PageHeader title="Zuweisereinstellungen" style={{ padding: 0 }} />
      <Form
        layout="horizontal"
        labelCol={{ xs: 24, sm: 24, md: 10, lg: 10, xl: 10, xxl: 10 }}
        labelAlign="left"
        colon={false}
        form={form}
        onFinish={onFinish}
      >
        <Divider orientation="left">Stammdaten</Divider>
        <Form.Item
          name="salutation"
          label="Anrede"
          rules={[{ type: 'enum', enum: Object.keys(DoctorSalutation), required: true }]}
        >
          <Select autoFocus>
            {Object.keys(DoctorSalutation).map(s => {
              return (
                <Option key={s} value={s}>
                  {translateDoctorSalutation(s)}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item name="preTitle" label="Titel vorangestellt">
          <Input />
        </Form.Item>
        <Form.Item name="postTitle" label="Titel nachgestellt">
          <Input />
        </Form.Item>
        <Form.Item name="firstName" label="Vorname">
          <Input />
        </Form.Item>
        <Form.Item
          name="lastName"
          label="Nachname bzw. Firma"
          tooltip="Relevant für z.B. HL7 und Dokumente"
          rules={[{ type: 'string', required: true, whitespace: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="name"
          label="Anzeigename"
          tooltip="Wird im UI angezeigt"
          rules={[{ type: 'string', required: true, whitespace: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="specialist" label="Facharzt">
          <Input />
        </Form.Item>
        <Form.Item
          name="street"
          label="Straße inkl. Hausnummer"
          rules={[{ type: 'string', required: true, whitespace: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="zip" label="PLZ" rules={[{ type: 'string', required: true, whitespace: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="city" label="Ort" rules={[{ type: 'string', required: true, whitespace: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="email" label="E-Mail" rules={[serverSideEmailRule]} validateDebounce={200}>
          <Input addonBefore={<MailOutlined />} />
        </Form.Item>
        <Form.Item name="phone" label="Telefon">
          <Input addonBefore={<PhoneOutlined />} />
        </Form.Item>

        <Divider orientation="left">Anforderung</Divider>

        <Form.Item
          name="billingPreference"
          label="Präferenz Privatverrechnung"
          rules={[{ type: 'enum', enum: Object.keys(BillingPreference) }]}
        >
          <Select>
            {Object.keys(BillingPreference).map(bp => {
              return (
                <Option key={bp} value={bp}>
                  {translateBillingPreference(bp)}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          name="addEmptyLabels"
          label="Zusätzliche Leeretiketten"
          tooltip="Anzahl der zusätzlichen Leeretiketten, die pro Anforderung automatisch gedruckt werden"
          rules={[
            {
              type: 'integer',
              required: true,
              min: 0,
              max: 15,
              message: 'Bitte Anzahl zw. 0 und 15 angeben',
            },
          ]}
        >
          <InputNumber style={{ width: '100%' }} inputMode="numeric" precision={0} max={15} min={0} step={1} />
        </Form.Item>
        <Form.Item name="autoDiagnose" valuePropName="checked">
          <Checkbox>
            Diagnose automatisch übernehmen <br />
            <Typography.Text type="secondary">
              <small>Wenn nur eine Diagnose verfügbar</small>
            </Typography.Text>
          </Checkbox>
        </Form.Item>
        <Form.Item name="printTransferDocument" valuePropName="checked">
          <Checkbox>Überweisungsschein beim Senden drucken</Checkbox>
        </Form.Item>

        <Button type="primary" htmlType="submit" icon={<SaveOutlined />} loading={saveLoading}>
          Speichern
        </Button>
      </Form>
    </MainContent>
  );
};
