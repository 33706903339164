import React, { useEffect, useState } from 'react';
import {
  Alert,
  App,
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Popover,
  Radio,
  Row,
  Select,
  Steps,
  theme,
  Typography,
} from 'antd';
import { MainContent } from '../../components/MainContent';
import {
  CheckOutlined,
  CloseOutlined,
  InfoCircleOutlined,
  RightOutlined,
  SendOutlined,
  TableOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router';
import { useQuery } from '@apollo/client';
import { useCheckParameters } from '../../hooks/useCheckParameters';
import { showParamWarning } from '../../components/showParamWarning';
import { translateIdCardType, translateInsuranceCategory } from '../../utils/enumHelpers';
import { API_DATE_FORMAT, SHORT_DATE_FORMAT } from '../../utils/dateFormatUtils';
import { useForm, useWatch } from 'antd/es/form/Form';
import { isValidSvnr } from '../../utils/svnrValidator';
import { PageHeader } from '@ant-design/pro-components';
import dayjs from 'dayjs';
import { useAppStore } from '../../hooks/store/useAppStore';
import { Gender, IdCardType, InsuranceCategory, PatientData } from '../../graphql/generated/graphql.ts';
import { graphql } from '../../graphql/generated';
import { useCurrentContextStore } from '../../hooks/store/useCurrentContextStore.ts';
import { serverSideEmailRule } from '../../utils/helpers.ts';
import { hasFrontDeskRole } from '../../utils/user.ts';
import { useAuth } from 'react-oidc-context';

const { useToken } = theme;
const envTag: string = window._env_.ENV_TAG;
const { Option } = Select;

type ExtendedPatientData = PatientData & {
  coInsured: boolean;
};

const PATIENT_REGISTRATION_QUERY = graphql(`
  query PatientRegistration($id: ID!) {
    doctor(id: $id) {
      id
      insurances {
        id
        shortName
        name
        code
      }
      flipParamNames
    }

    insurances {
      id
      code
      shortName
      name
    }
  }
`);

export const PatientRegistration: React.FC = () => {
  const {
    patientData,
    setPatientData,
    requestIdForUpdate,
    requestIdForReorder,
    setParameterIdsWithUnknownBilling,
    paramQueue,
    setParamQueue,
  } = useAppStore();
  const { currentDoctorId, primaryDoctorId } = useCurrentContextStore();

  const { token } = useToken();
  const navigate = useNavigate();
  const location = useLocation();
  const invalidFields = location.state?.invalidFields ?? [];
  const validateInitial = location.state?.validateInitial ?? false;
  const [checkParameters] = useCheckParameters();
  const [form] = useForm<ExtendedPatientData>();
  const coInsuredWatch = useWatch('coInsured', form);
  const insuranceCodeWatch = useWatch('insuranceCode', form);
  const idCardTypeWatch = useWatch('idCardType', form);
  const { notification, message } = App.useApp();
  const [saveLoading, setSaveLoading] = useState(false);
  const auth = useAuth();

  const { data, loading } = useQuery(PATIENT_REGISTRATION_QUERY, {
    variables: {
      id: hasFrontDeskRole(auth.user) ? primaryDoctorId : currentDoctorId,
    },
  });

  const save = async (formValues: ExtendedPatientData) => {
    setSaveLoading(true);

    // do not include field
    const { coInsured, ...rest } = formValues;
    const transformedPatientData = {
      ...rest,
      externalId: patientData?.externalId ?? '',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      birthday: (rest.birthday as any) /* dayjs */
        .format(API_DATE_FORMAT),
      insuranceCode: rest.insuranceCode ?? '', // insuranceCode cannot be undefined
      insuredPerson: rest.insuredPerson
        ? {
            ...rest.insuredPerson,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            birthday: (rest.insuredPerson.birthday as any) /* dayjs */
              .format(API_DATE_FORMAT),
          }
        : null,
    };

    try {
      const result = await checkParameters({
        variables: {
          doctorId: hasFrontDeskRole(auth.user) ? primaryDoctorId : currentDoctorId,
          patientData: transformedPatientData,
          selectedParameters: paramQueue.selectedParameters.map(sp => ({
            parameterId: sp.id,
            parameterShortName: sp.shortName,
            parameterLongName: sp.longName,
            billingType: sp.billingInfo.billingType,
            specialRateId: sp.billingInfo.specialRateId,
            diagnoseId: sp.billingInfo.diagnoseId,
          })),
        },
      });

      if (!result.data?.checkParameters) {
        message.error('Fehler beim Speichern des Patienten');
        return;
      }

      setParamQueue(state => ({
        ...state.paramQueue,
        selectedParameters:
          result.data?.checkParameters?.selectedParameters.map(it => ({
            ...it.requestableParameter,
            billingInfo: it.billingInfo,
          })) ?? [],
      }));
      setParameterIdsWithUnknownBilling(result.data.checkParameters.unknownBillingParameters.map(it => it.id));

      if (result.data.checkParameters.unknownBillingParameters.length) {
        showParamWarning(
          notification,
          'Verrechnungssituation geändert',
          'Für folgende Parameter hat sich die Verrechnungssituation geändert:',
          result.data.checkParameters.unknownBillingParameters.map(it =>
            data?.doctor?.flipParamNames ? it.longName : it.shortName
          )
        );
      }
      if (result.data.checkParameters.unavailableParameters.length) {
        showParamWarning(
          notification,
          'Parameter nicht verfügbar',
          'Folgende Parameter stehen nicht zur Verfügung:',
          result.data.checkParameters.unavailableParameters
        );
      }

      setPatientData(transformedPatientData);
      navigate('/anforderung/parameterauswahl');
    } catch (e) {
      message.error('Fehler beim Speichern des Patienten');
    } finally {
      setSaveLoading(false);
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      externalId: patientData?.externalId ?? '',
      title: patientData?.title ?? '',
      firstName: patientData?.firstName ?? '',
      lastName: patientData?.lastName ?? '',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      birthday: patientData?.birthday ? (dayjs(patientData.birthday) as any) : null,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      gender: patientData?.gender ?? (null as any), // hack to force the user to choose a value
      svnr: patientData?.svnr ?? '',
      insuranceCode: patientData?.insuranceCode ?? undefined, // undefined shows placeholder correctly
      insuranceCategory: patientData?.insuranceCategory ?? InsuranceCategory.ERWERBSTAETIGE,
      city: patientData?.city ?? '',
      zip: patientData?.zip ?? '',
      street: patientData?.street ?? '',
      country: patientData?.country ?? '',
      phone: patientData?.phone ?? '',
      email: patientData?.email ?? '',
      idCardType: patientData?.idCardType ?? IdCardType.NICHT_VORHANDEN,
      idCardNumber: patientData?.idCardNumber ?? '',
      idCardAuthority: patientData?.idCardAuthority ?? '',
      coInsured: !!patientData?.insuredPerson,
      insuredPerson: patientData?.insuredPerson
        ? {
            ...patientData.insuredPerson,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            birthday: patientData.insuredPerson.birthday ? (dayjs(patientData.insuredPerson.birthday) as any) : null,
          }
        : null,
    });

    if (patientData && validateInitial) {
      form.validateFields();
    }
  }, [form, patientData, validateInitial]);

  const [testPatientInsured, setTestPatientInsured] = useState(true);
  const [testPatientWithAddress, setTestPatientWithAddress] = useState(true);
  const [testPatientCoInsured, setTestPatientCoInsured] = useState(false);

  const fillTestPatient = () => {
    form.setFieldsValue({
      externalId: '',
      title: 'Mag.',
      firstName: 'Max Tester',
      lastName: 'Mustermann',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      birthday: dayjs('1998-08-10') as any,
      gender: Gender.MALE,
      svnr: testPatientInsured ? '1111111111' : '',
      insuranceCode:
        testPatientInsured && data?.doctor?.insurances.length ? data?.doctor.insurances[0].code : undefined,
      insuranceCategory: InsuranceCategory.ERWERBSTAETIGE,
      city: testPatientWithAddress ? 'Wien' : '',
      zip: testPatientWithAddress ? '1010' : '',
      street: testPatientWithAddress ? 'Karlsplatz 1/16' : '',
      country: '',
      phone: '0664 1234567',
      email: 'patient@example.org',
      idCardType: IdCardType.FUEHERSCHEIN,
      idCardNumber: '12345678',
      idCardAuthority: 'BH Wien',
      coInsured: testPatientCoInsured,
      insuredPerson: testPatientCoInsured
        ? {
            title: 'Dr.',
            firstName: 'John',
            lastName: 'Doe',
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            birthday: dayjs('1985-03-11') as any,
            gender: null,
            svnr: '1181010180',
            city: 'St. Pölten',
            zip: '3100',
            street: 'Hauptstraße 1',
            country: '',
            phone: '0676 7654321',
            email: 'insured@example.org',
          }
        : null,
    });
  };

  return (
    <MainContent size="medium">
      <Steps
        items={[
          {
            status: 'process',
            title: 'Patient',
            icon: <UserOutlined />,
          },
          {
            status: 'wait',
            title: 'Parameterauswahl',
            icon: <TableOutlined />,
          },
          {
            status: 'wait',
            title: requestIdForUpdate
              ? 'Bestehende Anforderung abschließen'
              : requestIdForReorder
                ? 'Nachforderung abschließen'
                : 'Neue Anforderung abschließen',
            icon: <SendOutlined />,
          },
        ]}
      />
      <PageHeader
        title={
          <span>
            Patient erfassen{' '}
            <Popover
              trigger={['click']}
              placement="right"
              title="Info Verrechnung"
              content={
                <div style={{ maxWidth: '500px' }}>
                  <h4>Privat an Patient</h4>
                  <p>
                    Eine Adressangabe (Straße, PLZ und Ort) ist erforderlich, um Privat an den Patienten anfordern zu
                    können.
                  </p>
                  <h4>Kassenanforderung</h4>
                  <p>
                    Eine gültige SVNR und die Auswahl einer Gesundheitskasse ist erforderlich, um Kassenleistungen in
                    Anspruch nehmen zu können.
                  </p>
                  <h4>Hauptversicherte Person</h4>
                  <p>
                    Wenn der Patient mitversichert ist, dann gelten oben genannte Kriterien auch für die
                    hauptversicherte Person.
                  </p>
                </div>
              }
            >
              <InfoCircleOutlined style={{ cursor: 'pointer' }} />
            </Popover>
          </span>
        }
        style={{ padding: 0, paddingBottom: token.paddingMD, paddingTop: token.paddingMD }}
        extra={
          envTag &&
          envTag !== 'prod' && (
            <>
              <Checkbox checked={testPatientInsured} onChange={e => setTestPatientInsured(e.target.checked)}>
                Versichert?
              </Checkbox>
              <Checkbox checked={testPatientWithAddress} onChange={e => setTestPatientWithAddress(e.target.checked)}>
                Mit Adresse?
              </Checkbox>
              <Checkbox checked={testPatientCoInsured} onChange={e => setTestPatientCoInsured(e.target.checked)}>
                Mitversichert?
              </Checkbox>
              <Button onClick={fillTestPatient} icon={<UserOutlined />}>
                Testpatient ausfüllen
              </Button>
            </>
          )
        }
      />
      {!!invalidFields.length && (
        <>
          <Alert
            type="warning"
            showIcon
            message="Bitte ergänzen Sie die Angaben zum Patient"
            closable
            description={`Folgende Felder konnten nicht korrekt vom AIS übernommen werden: ${invalidFields.join(', ')}`}
          />
          <p />
        </>
      )}
      <Form<ExtendedPatientData> labelCol={{ span: 6 }} colon={false} labelAlign="left" form={form} onFinish={save}>
        <Row gutter={[16, 0]}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Form.Item name="title" label="Titel">
              <Input />
            </Form.Item>
            <Form.Item name="firstName" label="Vorname" rules={[{ required: true, whitespace: true }]}>
              <Input autoFocus />
            </Form.Item>
            <Form.Item name="lastName" label="Nachname" rules={[{ required: true, whitespace: true }]}>
              <Input />
            </Form.Item>
            <Form.Item label="Geburtstag" name="birthday" rules={[{ type: 'date', required: true }]}>
              <DatePicker
                style={{ width: '100%' }}
                format={SHORT_DATE_FORMAT}
                placeholder="TT.MM.JJJJ"
                allowClear={false}
                disabledDate={current =>
                  current && (current.isAfter(dayjs()) || current.isBefore(dayjs().subtract(120, 'years')))
                }
              />
            </Form.Item>
            <Form.Item
              name="gender"
              label="Geschlecht"
              rules={[
                {
                  type: 'enum',
                  required: true,
                  enum: [Gender.FEMALE, Gender.MALE],
                  message: 'Geben Sie das Geschlecht an',
                },
              ]}
            >
              <Radio.Group
                options={[
                  { label: 'weiblich', value: Gender.FEMALE },
                  { label: 'männlich', value: Gender.MALE },
                ]}
              />
            </Form.Item>
            <Form.Item
              name="svnr"
              label="SVNR"
              rules={[
                {
                  validator: (_, value) =>
                    !value
                      ? Promise.resolve()
                      : isValidSvnr(value)
                        ? Promise.resolve()
                        : Promise.reject('Die eingegebene SVNR ist ungültig'),
                },
                { required: !!insuranceCodeWatch },
              ]}
            >
              <Input placeholder="10-stellig" maxLength={10} />
            </Form.Item>
            <Form.Item
              name="insuranceCode"
              label="Kasse"
              tooltip={
                <>
                  <CheckOutlined style={{ color: token['green-7'] }} /> anforderbar
                  <br />
                  <CloseOutlined style={{ color: token.colorError }} /> nicht anforderbar
                </>
              }
            >
              <Select
                popupMatchSelectWidth={false}
                showSearch
                allowClear
                placeholder="Privat"
                optionFilterProp="search"
                optionLabelProp="taglabel"
                loading={loading}
              >
                {[...(data?.insurances ?? [])]
                  .sort((a, b) => a.shortName.localeCompare(b.shortName))
                  .map(insurance => {
                    const indicator = data?.doctor?.insurances?.find(di => di.code === insurance.code) ? (
                      <CheckOutlined style={{ color: token['green-7'] }} />
                    ) : (
                      <CloseOutlined style={{ color: token.colorError }} />
                    );
                    return (
                      <Option
                        key={insurance.code}
                        value={insurance.code}
                        taglabel={
                          <span>
                            {indicator} {insurance.shortName}
                          </span>
                        }
                        search={insurance.shortName + ' ' + insurance.name}
                      >
                        <div>
                          {indicator} {insurance.shortName}
                        </div>
                        <Typography.Text type="secondary">{insurance.name}</Typography.Text>
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
            <Form.Item
              name="insuranceCategory"
              label="Kategorie"
              wrapperCol={{ span: 18 }}
              rules={[
                {
                  type: 'enum',
                  enum: Object.keys(InsuranceCategory),
                },
              ]}
            >
              <Select popupMatchSelectWidth={false} showSearch optionFilterProp="children">
                {Object.keys(InsuranceCategory).map(category => (
                  <Option key={category} value={category}>
                    {translateInsuranceCategory(category)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Form.Item name="street" label="Straße u. Nr.">
              <Input />
            </Form.Item>
            <Form.Item name="city" label="Ort">
              <Input />
            </Form.Item>
            <Row>
              <Col span={10}>
                <Form.Item labelCol={{ span: 14 }} name="zip" label="PLZ">
                  <Input style={{ marginLeft: '4px' }} />
                </Form.Item>
              </Col>
              <Col span={14}>
                <Form.Item
                  labelCol={{ span: 5 }}
                  name="country"
                  label={<span style={{ paddingLeft: token.paddingSM }}>Land</span>}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item name="phone" label="Telefon">
              <Input />
            </Form.Item>
            <Form.Item name="email" label="E-Mail" rules={[serverSideEmailRule]} validateDebounce={200}>
              <Input />
            </Form.Item>
            <Form.Item
              name="idCardType"
              label="Ausweis"
              wrapperCol={{ span: 18 }}
              rules={[
                {
                  type: 'enum',
                  enum: Object.keys(IdCardType),
                },
              ]}
            >
              <Select
                popupMatchSelectWidth={false}
                showSearch
                optionFilterProp="children"
                onChange={value => {
                  if (value === IdCardType.NICHT_VORHANDEN) {
                    form.setFieldValue('idCardNumber', '');
                    form.setFieldValue('idCardAuthority', '');
                  }
                }}
              >
                {Object.keys(IdCardType).map(type => (
                  <Option key={type} value={type}>
                    {translateIdCardType(type)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="idCardNumber" label="Ausweisnummer">
              <Input disabled={idCardTypeWatch === IdCardType.NICHT_VORHANDEN} />
            </Form.Item>
            <Form.Item name="idCardAuthority" label="Behörde">
              <Input disabled={idCardTypeWatch === IdCardType.NICHT_VORHANDEN} />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item noStyle name="coInsured" valuePropName="checked">
          <Checkbox
            onChange={e => {
              form.setFieldValue(
                'insuredPerson',
                e.target.checked
                  ? {
                      title: '',
                      firstName: '',
                      lastName: '',
                      birthday: '',
                      gender: null,
                      svnr: '',
                      city: '',
                      zip: '',
                      street: '',
                      country: '',
                      phone: '',
                      email: '',
                    }
                  : null
              );
            }}
          >
            <h4 style={{ margin: 0 }}>Mitversichert?</h4>
          </Checkbox>
        </Form.Item>
        {coInsuredWatch && (
          <>
            <Divider orientation="left">Hauptversicherte Person bzw. Rechnungsempfänger</Divider>
            <Row gutter={[16, 0]}>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <Form.Item name={['insuredPerson', 'title']} label="Titel">
                  <Input />
                </Form.Item>
                <Form.Item
                  name={['insuredPerson', 'firstName']}
                  label="Vorname"
                  rules={[{ required: true, whitespace: true }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name={['insuredPerson', 'lastName']}
                  label="Nachname"
                  rules={[{ required: true, whitespace: true }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Geburtstag"
                  name={['insuredPerson', 'birthday']}
                  rules={[{ type: 'date', required: true }]}
                >
                  <DatePicker
                    style={{ width: '100%' }}
                    format={SHORT_DATE_FORMAT}
                    placeholder="TT.MM.JJJJ"
                    allowClear={false}
                    disabledDate={current =>
                      current && (current.isAfter(dayjs()) || current.isBefore(dayjs().subtract(120, 'years')))
                    }
                  />
                </Form.Item>
                <Form.Item
                  name={['insuredPerson', 'gender']}
                  label="Geschlecht"
                  rules={[
                    {
                      type: 'enum',
                      required: false,
                      enum: [Gender.FEMALE, Gender.MALE],
                      message: 'Geben Sie das Geschlecht an',
                    },
                  ]}
                >
                  <Radio.Group
                    options={[
                      { label: 'weiblich', value: Gender.FEMALE },
                      { label: 'männlich', value: Gender.MALE },
                    ]}
                  />
                </Form.Item>
                <Form.Item
                  name={['insuredPerson', 'svnr']}
                  label="SVNR"
                  rules={[
                    {
                      required: true,
                      validator: (_, value) =>
                        isValidSvnr(value) ? Promise.resolve() : Promise.reject('Die eingegebene SVNR ist ungültig'),
                    },
                  ]}
                >
                  <Input placeholder="10-stellig" maxLength={10} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <Form.Item name={['insuredPerson', 'street']} label="Straße u. Nr.">
                  <Input />
                </Form.Item>
                <Form.Item name={['insuredPerson', 'zip']} label="PLZ">
                  <Input />
                </Form.Item>
                <Form.Item name={['insuredPerson', 'city']} label="Ort">
                  <Input />
                </Form.Item>
                <Form.Item name={['insuredPerson', 'country']} label="Land">
                  <Input />
                </Form.Item>
                <Form.Item name={['insuredPerson', 'phone']} label="Telefon">
                  <Input />
                </Form.Item>
                <Form.Item
                  name={['insuredPerson', 'email']}
                  label="E-Mail"
                  rules={[serverSideEmailRule]}
                  validateDebounce={200}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
        <Divider />
        <Button htmlType="submit" type="primary" icon={<RightOutlined />} loading={saveLoading}>
          Weiter zur Parameterauswahl
        </Button>
      </Form>
    </MainContent>
  );
};
