import React, { useEffect, useState } from 'react';
import { MainContent } from '../../../components/MainContent';
import { useParams } from 'react-router';
import { Button, Dropdown, Input, Space, Table, theme, Tooltip } from 'antd';
import { useQuery } from '@apollo/client';
import { ColumnsType } from 'antd/es/table';
import {
  CheckOutlined,
  EditOutlined,
  EllipsisOutlined,
  FilePdfOutlined,
  InfoCircleOutlined,
  PlusCircleOutlined,
  SearchOutlined,
  EyeInvisibleOutlined,
} from '@ant-design/icons';
import { useDebounce } from 'use-debounce';
import { Markable } from '../../../components/Markable';
import { CreateFormModal } from './forms/CreateFormModal';
import { tableActionCell } from '../../../styles/globalCss';
import { FormEditorDrawer } from './forms/FormEditorDrawer';
import { FormDocumentModal } from './forms/FormDocumentModal';
import { translateFormCustomizer } from '../../../utils/enumHelpers.ts';
import { graphql } from '../../../graphql/generated';
import { FormsQuery } from '../../../graphql/generated/graphql.ts';

export const FORMS_QUERY = graphql(`
  query Forms($labId: ID!) {
    labForms(labId: $labId) {
      id
      name
      header
      description
      pickup
      printBeforeSend
      printRequired
      printCopies
      hasDocument
      direct
      restore
      customizer
      requiredByFrontDesk
      doctors {
        id
      }
      fields {
        id
        name
        label
        placeholder
        required
        help
        defaultValue
        regex
        type
        options {
          label
          value
          selected
        }
      }
    }

    doctors {
      id
      name
      meAddress
      firstName
      lastName
      salutation
      preTitle
      postTitle
      disabled
    }
  }
`);

export type Form = NonNullable<FormsQuery['labForms']>[number];

export const Forms: React.FC = () => {
  const { token } = theme.useToken();
  const { id: labId } = useParams<{ id: string }>();
  const [search, setSearch] = useState<string | null>(null);
  const [debouncedSearch] = useDebounce(search, 250);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [editForm, setEditForm] = useState<Form | null>(null);
  const [filteredForms, setFilteredForms] = useState<Form[]>([]);
  const [documentForm, setDocumentForm] = useState<Form | null>(null);

  const { data, loading, refetch } = useQuery(FORMS_QUERY, {
    variables: {
      labId: labId ?? '',
    },
    pollInterval: 60000,
  });

  const columns: ColumnsType<Form> = [
    {
      title: 'Bezeichnung',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      width: 150,
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: value => <Markable tokens={debouncedSearch ?? ''}>{value}</Markable>,
    },
    {
      title: 'Überschrift',
      dataIndex: 'header',
      key: 'header',
      ellipsis: true,
      width: 150,
      sorter: (a, b) => a.header.localeCompare(b.header),
      render: value => <Markable tokens={debouncedSearch ?? ''}>{value}</Markable>,
    },
    {
      title: 'Felder',
      dataIndex: 'fields',
      key: 'fields',
      ellipsis: true,
      width: 150,
      sorter: (a, b) => a.fields.length - b.fields.length,
      render: value => value.length,
    },
    {
      title: 'Dokument',
      dataIndex: 'hasDocument',
      key: 'hasDocument',
      ellipsis: true,
      width: 150,
      sorter: (a, b) => Number(a.hasDocument) - Number(b.hasDocument),
      render: value => (value ? <CheckOutlined /> : ''),
    },
    {
      title: 'Customizer',
      dataIndex: 'customizer',
      key: 'customizer',
      ellipsis: true,
      width: 150,
      sorter: (a, b) => a.customizer.localeCompare(b.customizer),
      render: value => translateFormCustomizer(value),
    },
    {
      title: 'Drucken',
      dataIndex: 'printRequired',
      key: 'printRequired',
      ellipsis: true,
      width: 150,
      sorter: (a, b) => Number(a.printRequired) - Number(b.printRequired),
      render: value => (value ? <CheckOutlined /> : ''),
    },
    {
      title: 'Vor Senden drucken',
      dataIndex: 'printBeforeSend',
      key: 'printBeforeSend',
      ellipsis: true,
      width: 150,
      sorter: (a, b) => Number(a.printBeforeSend) - Number(b.printBeforeSend),
      render: value => (value ? <CheckOutlined /> : ''),
    },
    {
      title: 'Kopien',
      dataIndex: 'printCopies',
      key: 'printCopies',
      ellipsis: true,
      width: 90,
      sorter: (a, b) => a.printCopies - b.printCopies,
    },
    {
      title: 'Elekt. übertragen',
      dataIndex: 'pickup',
      key: 'pickup',
      ellipsis: true,
      width: 150,
      sorter: (a, b) => Number(a.pickup) - Number(b.pickup),
      render: value => (value ? <CheckOutlined /> : ''),
    },
    {
      title: (
        <>
          Direkt{' '}
          <Tooltip title="Ohne Anforderung mit Patienten- und Formulardaten erstellbar">
            <InfoCircleOutlined />
          </Tooltip>
        </>
      ),
      dataIndex: 'direct',
      key: 'direct',
      ellipsis: true,
      width: 90,
      sorter: (a, b) => Number(a.direct) - Number(b.direct),
      render: (_, record) =>
        record.direct ? (
          <Space>
            <CheckOutlined />
            {!!record.doctors.length && <EyeInvisibleOutlined />}
          </Space>
        ) : null,
    },
    {
      title: (
        <>
          Wiederherstellen{' '}
          <Tooltip title="Beim Wiederholen einer Anforderung die Formularfelder wiederherstellen">
            <InfoCircleOutlined />
          </Tooltip>
        </>
      ),
      dataIndex: 'restore',
      key: 'restore',
      ellipsis: true,
      width: 170,
      sorter: (a, b) => Number(a.restore) - Number(b.restore),
      render: value => (value ? <CheckOutlined /> : ''),
    },
    {
      title: '',
      key: 'actions',
      fixed: 'right',
      align: 'right',
      ellipsis: true,
      width: '50px',
      className: tableActionCell,
      render: (_, record) => {
        return (
          <Dropdown
            menu={{
              items: [
                {
                  key: 'edit',
                  icon: <EditOutlined />,
                  onClick: () => setEditForm(record),
                  label: 'Bearbeiten',
                },
                {
                  key: 'document',
                  icon: <FilePdfOutlined />,
                  onClick: () => setDocumentForm(record),
                  label: 'Dokument ändern',
                },
              ],
            }}
            trigger={['click']}
            placement="bottomRight"
          >
            <Button icon={<EllipsisOutlined style={{ fontSize: '20px', verticalAlign: 'middle' }} />} type="text" />
          </Dropdown>
        );
      },
    },
  ];

  useEffect(() => {
    const filtered = data?.labForms?.filter(d => {
      if (debouncedSearch && debouncedSearch.length) {
        const searchValue = debouncedSearch.toLowerCase();
        return d.name.toLowerCase().includes(searchValue) || d.header.toLowerCase().includes(searchValue);
      }
      return true;
    });
    setFilteredForms(filtered ?? []);
  }, [debouncedSearch, data]);

  return (
    <MainContent size="full">
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: token.paddingSM }}>
        <Input
          autoFocus
          allowClear
          placeholder="Suche"
          value={search ?? ''}
          onChange={e => setSearch(e.target.value)}
          prefix={<SearchOutlined />}
          suffix={
            <Tooltip title="Suche nach Bezeichnung oder Überschrift">
              <InfoCircleOutlined />
            </Tooltip>
          }
          style={{ width: '300px', marginRight: token.paddingSM }}
        />
        <Button
          type="primary"
          icon={<PlusCircleOutlined />}
          disabled={loading}
          onClick={() => setCreateModalOpen(true)}
        >
          Neues Formular
        </Button>
      </div>
      <Table<Form>
        scroll={{ x: 'max-content' }}
        rowKey={record => record.id}
        size="middle"
        showSorterTooltip={false}
        dataSource={filteredForms}
        pagination={{
          showQuickJumper: true,
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]} bis ${range[1]} von ${total} Formularen`,
        }}
        loading={loading}
        columns={columns}
      />
      <CreateFormModal
        open={createModalOpen}
        onSuccess={() => refetch()}
        onClose={() => setCreateModalOpen(false)}
        labId={labId ?? ''}
      />
      <FormEditorDrawer
        form={editForm}
        onClose={() => setEditForm(null)}
        onSuccess={refetch}
        doctors={data?.doctors ?? []}
      />
      <FormDocumentModal
        form={documentForm}
        onClose={() => {
          refetch();
          setDocumentForm(null);
        }}
      />
    </MainContent>
  );
};
