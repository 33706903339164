import React, { ReactNode, useEffect, useState } from 'react';
import { Button, Flex, Input, Modal, Popover, Table, theme, Tooltip } from 'antd';
import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  ExportOutlined,
  EyeOutlined,
  InfoCircleOutlined,
  MinusOutlined,
  PushpinTwoTone,
  ReloadOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { ColumnsType } from 'antd/es/table';
import { Markable } from '../../../../components/Markable';
import { useDebouncedCallback } from 'use-debounce';
import { ellipsis } from '../../../../utils/ellipsis';
import { UpdateCatalogParametersModal } from './UpdateCatalogParametersModal';
import { openExternalLink } from '../../../../utils/linkOpener';
import { tableHoverPointer } from '../../../../styles/globalCss';
import { css } from '@emotion/css';
import { currency } from '../../../../utils/currency';
import { graphql } from '../../../../graphql/generated';
import { CatalogParametersQuery } from '../../../../graphql/generated/graphql.ts';
import { TableList } from '../../../../components/TableList.tsx';

const { useToken } = theme;

export const CATALOG_QUERY = graphql(`
  query CatalogParameters($catalogId: ID!) {
    catalog(id: $catalogId) {
      id
      name
      catalogParameters {
        id
        useSpecimens
        specimens {
          id
          name
          classification
        }
        useSpecialRates
        specialRates {
          id
          shortName
        }
        useForms
        forms {
          id
          name
        }
        useDoctorBilling
        doctorBilling
        usePatientBilling
        patientBilling
        useLgDoctorBilling
        lgDoctorBilling
        useInsuranceDefinitions
        useLgInsuranceDefinitions
        usePriceDoctor
        priceDoctor
        usePricePatient
        pricePatient
        usePriceLgDoctor
        priceLgDoctor
        parameter {
          id
          shortName
          longName
          edifactNumber
          synonyms
          description
          link
          deactivated
          withoutParameters {
            id
            shortName
          }
          withParameters {
            id
            shortName
          }
          specimens {
            id
            name
            classification
          }
          specialRates {
            id
            shortName
          }
          forms {
            id
            name
          }
          doctorBilling
          patientBilling
          lgDoctorBilling
          priceDoctor
          pricePatient
          priceLgDoctor
        }
      }
    }
  }
`);

const MarkerCell: React.FC<{ overridden: boolean; children?: ReactNode }> = ({ overridden, children }) => {
  const { token } = useToken();
  return (
    <Flex>
      <PushpinTwoTone
        className={css`
          margin-right: ${token.paddingXS}px;
          font-size: ${token.fontSizeLG}px;
          margin-left: ${token.paddingSM}px;
        `}
        twoToneColor={overridden ? token['magenta-6'] : token['green-6']}
      />
      {children}
    </Flex>
  );
};

type CatalogParameter = NonNullable<CatalogParametersQuery['catalog']>['catalogParameters'][number];

export const CatalogParameterModal: React.FC<{
  catalogId: string | null;
  labId: string;
  onClose: () => void;
}> = ({ catalogId, labId, onClose }) => {
  const { token } = useToken();
  const [filteredCatalogParameters, setFilteredCatalogParameters] = useState<CatalogParameter[]>([]);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [searchValue, setSearchValue] = useState<string>('');

  const { data, loading, refetch } = useQuery(CATALOG_QUERY, {
    variables: {
      catalogId: catalogId ?? '',
    },
    skip: catalogId === null,
  });

  const debouncedSearch = useDebouncedCallback((value: string) => {
    setSearchValue(value);
    setFilteredCatalogParameters(
      data?.catalog?.catalogParameters.filter(p => {
        if (value.length) {
          return value
            .toLowerCase()
            .split(' ')
            .some(
              token =>
                p.parameter.shortName.toLowerCase().includes(token) ||
                p.parameter.longName.toLowerCase().includes(token) ||
                (p.parameter.edifactNumber?.toString().includes(token) ?? false) ||
                p.parameter.synonyms.some(it => it.toLowerCase().includes(token))
            );
        }
        return true;
      }) ?? []
    );
  }, 300);

  useEffect(() => {
    if (data) {
      debouncedSearch(searchValue);
    }
  }, [data, searchValue, debouncedSearch]);

  const close = () => {
    setSelectedIds([]);
    onClose();
  };

  const columns: ColumnsType<CatalogParameter> = [
    {
      title: 'Kurzbezeichnung',
      dataIndex: ['parameter', 'shortName'],
      key: 'parameter.shortName',
      defaultSortOrder: 'ascend',
      ellipsis: true,
      className: css`
        min-width: 150px;
        max-width: 200px;
      `,
      sorter: (a, b) => a.parameter.shortName.localeCompare(b.parameter.shortName),
      render: value => <Markable tokens={searchValue ?? ''}>{value}</Markable>,
    },
    {
      title: 'Langbezeichnung',
      dataIndex: ['parameter', 'longName'],
      key: 'parameter.longName',
      ellipsis: true,
      className: css`
        min-width: 150px;
        max-width: 200px;
      `,
      sorter: (a, b) => a.parameter.longName.localeCompare(b.parameter.longName),
      render: value => <Markable tokens={searchValue ?? ''}>{value}</Markable>,
    },
    {
      title: 'Edifact',
      dataIndex: ['parameter', 'edifactNumber'],
      key: 'parameter.edifactNumber',
      ellipsis: true,
      className: css`
        min-width: 80px;
      `,
      sorter: (a, b) => (a.parameter.edifactNumber ?? 0) - (b.parameter.edifactNumber ?? 0),
      render: value => <Markable tokens={searchValue ?? ''}>{value}</Markable>,
    },
    {
      title: 'Beschr.',
      dataIndex: ['parameter', 'description'],
      key: 'parameter.description',
      ellipsis: true,
      className: css`
        min-width: 50px;
      `,
      render: value => {
        if (!value) {
          return;
        }
        return (
          <Popover
            trigger={['click']}
            content={<div style={{ whiteSpace: 'pre-wrap', maxWidth: '500px' }}>{value}</div>}
          >
            <Button
              type="link"
              className={css`
                height: 20px;
                margin: 0;
                padding: 0;
              `}
              icon={<EyeOutlined />}
              onClick={e => e.stopPropagation()}
            />
          </Popover>
        );
      },
    },
    {
      title: 'Link',
      dataIndex: ['parameter', 'link'],
      key: 'parameter.link',
      ellipsis: true,
      className: css`
        min-width: 50px;
      `,
      render: value => {
        if (!value) {
          return;
        }
        return (
          <Button
            type="link"
            className={css`
              height: 20px;
              margin: 0;
              padding: 0;
            `}
            icon={<ExportOutlined />}
            onClick={e => {
              e.stopPropagation();
              openExternalLink(value);
            }}
          />
        );
      },
    },
    {
      title: 'Aktiv',
      dataIndex: ['parameter', 'deactivated'],
      key: 'parameter.deactivated',
      ellipsis: true,
      className: css`
        min-width: 70px;
      `,
      sorter: (a, b) => Number(b.parameter.deactivated) - Number(a.parameter.deactivated),
      render: value => (!value ? <CheckOutlined /> : <CloseOutlined />),
    },
    {
      title: 'Nur mit',
      dataIndex: ['parameter', 'withParameters'],
      key: 'parameter.withParameters',
      ellipsis: true,
      className: css`
        min-width: 80px;
      `,
      render: (_, record) => {
        if (!record.parameter.withParameters.length) {
          return <MinusOutlined />;
        }
        return (
          <TableList entries={record.parameter.withParameters.map(it => it.shortName)} maxEntries={3} withinModal />
        );
      },
    },
    {
      title: 'Nicht mit',
      dataIndex: ['parameter', 'withoutParameters'],
      key: 'parameter.withoutParameters',
      ellipsis: true,
      className: css`
        min-width: 80px;
      `,
      render: (_, record) => {
        if (!record.parameter.withoutParameters.length) {
          return <MinusOutlined />;
        }
        return (
          <TableList entries={record.parameter.withoutParameters.map(it => it.shortName)} maxEntries={3} withinModal />
        );
      },
    },
    {
      title: 'Material',
      dataIndex: 'specimens',
      key: 'specimens',
      ellipsis: true,
      className: css`
        min-width: 100px;
      `,
      sorter: (a, b) => Number(a.useSpecimens) - Number(b.useSpecimens),
      render: (_, record) => {
        const value = record.useSpecimens ? record.specimens : record.parameter.specimens;
        return (
          <MarkerCell overridden={record.useSpecimens}>
            {value.length ? (
              <TableList entries={value.map(it => `${it.name} - ${it.classification}`)} maxEntries={3} withinModal />
            ) : (
              <MinusOutlined />
            )}
          </MarkerCell>
        );
      },
    },
    {
      title: 'P.Zuweiser',
      dataIndex: 'doctorBilling',
      key: 'doctorBilling',
      ellipsis: true,
      className: css`
        min-width: 110px;
      `,
      sorter: (a, b) => Number(a.useDoctorBilling) - Number(b.useDoctorBilling),
      render: (_, record) => {
        const value = record.useDoctorBilling ? record.doctorBilling : record.parameter.doctorBilling;
        return (
          <MarkerCell overridden={record.useDoctorBilling}>{value ? <CheckOutlined /> : <CloseOutlined />}</MarkerCell>
        );
      },
    },
    {
      title: 'P.Patient',
      dataIndex: 'patientBilling',
      key: 'patientBilling',
      ellipsis: true,
      className: css`
        min-width: 100px;
      `,
      sorter: (a, b) => Number(a.usePatientBilling) - Number(b.usePatientBilling),
      render: (_, record) => {
        const value = record.usePatientBilling ? record.patientBilling : record.parameter.patientBilling;
        return (
          <MarkerCell overridden={record.usePatientBilling}>{value ? <CheckOutlined /> : <CloseOutlined />}</MarkerCell>
        );
      },
    },
    {
      title: 'LG P.Zuweiser',
      dataIndex: 'lgDoctorBilling',
      key: 'lgDoctorBilling',
      ellipsis: true,
      className: css`
        min-width: 110px;
      `,
      sorter: (a, b) => Number(a.useLgDoctorBilling) - Number(b.useLgDoctorBilling),
      render: (_, record) => {
        const value = record.useLgDoctorBilling ? record.lgDoctorBilling : record.parameter.lgDoctorBilling;
        return (
          <MarkerCell overridden={record.useLgDoctorBilling}>
            {value ? <CheckOutlined /> : <CloseOutlined />}
          </MarkerCell>
        );
      },
    },
    {
      title: 'Kassen',
      key: 'insuranceDefinitions',
      ellipsis: true,
      className: css`
        min-width: 80px;
      `,
      sorter: (a, b) => Number(a.useInsuranceDefinitions) - Number(b.useInsuranceDefinitions),
      render: (_, record) => <MarkerCell overridden={record.useInsuranceDefinitions} />,
    },
    {
      title: 'LG-Kassen',
      key: 'lgInsuranceDefinitions',
      ellipsis: true,
      className: css`
        min-width: 100px;
      `,
      sorter: (a, b) => Number(a.useLgInsuranceDefinitions) - Number(b.useLgInsuranceDefinitions),
      render: (_, record) => <MarkerCell overridden={record.useLgInsuranceDefinitions} />,
    },
    {
      title: 'Sondertarife',
      dataIndex: 'specialRates',
      key: 'specialRates',
      ellipsis: true,
      className: css`
        min-width: 120px;
      `,
      sorter: (a, b) => Number(a.useSpecialRates) - Number(b.useSpecialRates),
      render: (_, record) => {
        const value = record.useSpecialRates ? record.specialRates : record.parameter.specialRates;
        return (
          <MarkerCell overridden={record.useSpecialRates}>
            {value.length ? (
              <TableList entries={value.map(it => it.shortName)} maxEntries={3} withinModal />
            ) : (
              <MinusOutlined />
            )}
          </MarkerCell>
        );
      },
    },
    {
      title: 'Formulare',
      dataIndex: 'forms',
      key: 'forms',
      ellipsis: true,
      className: css`
        min-width: 120px;
      `,
      sorter: (a, b) => Number(a.useForms) - Number(b.useForms),
      render: (_, record) => {
        const value = record.useForms ? record.forms : record.parameter.forms;
        return (
          <MarkerCell overridden={record.useForms}>
            {value.length ? (
              <TableList entries={value.map(it => it.name)} maxEntries={3} withinModal />
            ) : (
              <MinusOutlined />
            )}
          </MarkerCell>
        );
      },
    },
    {
      title: 'Preis Zuweiser',
      dataIndex: 'priceDoctor',
      key: 'priceDoctor',
      ellipsis: true,
      className: css`
        min-width: 130px;
      `,
      sorter: (a, b) => Number(a.usePriceDoctor) - Number(b.usePriceDoctor),
      render: (_, record) => {
        const value = record.usePriceDoctor ? record.priceDoctor : record.parameter.priceDoctor;
        return <MarkerCell overridden={record.usePriceDoctor}>{currency(value)}</MarkerCell>;
      },
    },
    {
      title: 'Preis Patient',
      dataIndex: 'pricePatient',
      key: 'pricePatient',
      ellipsis: true,
      className: css`
        min-width: 130px;
      `,
      sorter: (a, b) => Number(a.usePricePatient) - Number(b.usePricePatient),
      render: (_, record) => {
        const value = record.usePricePatient ? record.pricePatient : record.parameter.pricePatient;
        return <MarkerCell overridden={record.usePricePatient}>{currency(value)}</MarkerCell>;
      },
    },
    {
      title: 'Preis LG-Zuweiser',
      dataIndex: 'priceLgDoctor',
      key: 'priceLgDoctor',
      ellipsis: true,
      className: css`
        min-width: 130px;
      `,
      sorter: (a, b) => Number(a.usePriceLgDoctor) - Number(b.usePriceLgDoctor),
      render: (_, record) => {
        const value = record.usePriceLgDoctor ? record.priceLgDoctor : record.parameter.priceLgDoctor;
        return <MarkerCell overridden={record.usePriceLgDoctor}>{currency(value)}</MarkerCell>;
      },
    },
  ];

  return (
    <Modal
      className={css`
        top: 16px;
        padding-bottom: 0;

        .ant-modal-body {
          padding: 0;
        }

        .ant-modal-footer {
          border-top: none;
        }
      `}
      width="100%"
      title={
        <Flex align="center">
          Katalog-Parameter von {ellipsis(data?.catalog?.name ?? '', 50)}
          <Input
            allowClear
            placeholder="Suche"
            onChange={e => debouncedSearch(e.target.value)}
            prefix={<SearchOutlined />}
            suffix={
              <Tooltip title="Suche nach Kurzbezeichnung, Langbezeichnung, Edifact-Nummer oder Synonyme">
                <InfoCircleOutlined />
              </Tooltip>
            }
            style={{ width: '300px', marginLeft: token.paddingSM }}
          />
          <Flex
            className={css`
              font-weight: normal;
              font-size: ${token.fontSizeSM}px;
            `}
          >
            <MarkerCell overridden={false}>geerbte Eigenschaft</MarkerCell>
            <MarkerCell overridden={true}>überschriebene Eigenschaft</MarkerCell>
          </Flex>
        </Flex>
      }
      open={!!catalogId}
      footer={[
        <Button
          type="primary"
          key="edit"
          icon={<EditOutlined />}
          disabled={loading || !selectedIds.length}
          onClick={() => setEditModalVisible(true)}
        >
          {selectedIds.length} Parameter bearbeiten
        </Button>,
        <Button key="refresh" icon={<ReloadOutlined />} onClick={() => refetch()}>
          Daten aktualisieren
        </Button>,
        <Button key="close" icon={<CloseOutlined />} onClick={close}>
          Schließen
        </Button>,
      ]}
      onCancel={close}
      destroyOnClose
    >
      <Table<CatalogParameter>
        scroll={{ x: 'max-content', y: 'calc(100vh - 265px)' }}
        rowKey={record => record.id}
        size="middle"
        showSorterTooltip={false}
        dataSource={filteredCatalogParameters}
        loading={loading}
        pagination={{
          position: ['bottomCenter'],
          defaultPageSize: 50,
          showQuickJumper: true,
          pageSizeOptions: ['50', '100', '500', '1000'],
        }}
        columns={columns}
        rowSelection={{
          type: 'checkbox',
          selectedRowKeys: selectedIds,
          preserveSelectedRowKeys: true,
          onChange: selectedKeys => setSelectedIds(selectedKeys as string[]),
        }}
        onRow={record => ({
          onClick: e => {
            e.preventDefault();
            if (selectedIds.includes(record.id)) {
              setSelectedIds(selectedIds.filter(it => it !== record.id));
              return;
            }
            setSelectedIds([...selectedIds, record.id]);
          },
        })}
        rowClassName={tableHoverPointer}
      />
      <UpdateCatalogParametersModal
        onSuccess={refetch}
        onClose={() => setEditModalVisible(false)}
        visible={editModalVisible}
        labId={labId}
        cpIds={selectedIds}
      />
    </Modal>
  );
};
