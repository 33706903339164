import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { Alert, Button, Flex, Space } from 'antd';
import { CloseOutlined, ExportOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { openExternalLink } from './utils/linkOpener.ts';
import { css } from '@emotion/css';
import { graphql } from './graphql/generated';
import { SystemStatusType } from './graphql/generated/graphql.ts';
import { UpdateEvent } from './socket/types.ts';
import { useSubscription } from './socket/useSubscription.ts';
import _ from 'lodash';

const SYSTEM_STATUS_WRAPPER_QUERY = graphql(`
  query SystemStatusWrapper {
    systemStatus {
      id
      active
      heading
      content
      link
      type
    }
  }
`);

export const SystemStatusWrapper: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [closed, setClosed] = useState(false);

  const { data, previousData, refetch } = useQuery(SYSTEM_STATUS_WRAPPER_QUERY);

  useEffect(() => {
    if (!_.isEqual(data?.systemStatus, previousData?.systemStatus)) {
      setClosed(false);
    }
  }, [data, previousData]);

  const callback = useCallback(
    (event: UpdateEvent) => {
      console.debug('received UI update: ' + JSON.stringify(event));
      refetch();
    },
    [refetch]
  );

  useSubscription({ type: 'system-status-updated' }, callback);

  let type: 'info' | 'warning' | 'error' = 'info';
  if (data?.systemStatus?.type === SystemStatusType.ERROR) {
    type = 'error';
  } else if (data?.systemStatus?.type === SystemStatusType.WARNING) {
    type = 'warning';
  }

  return (
    <Flex
      className={css`
        height: 100%;
      `}
      vertical
    >
      <div
        className={css`
          max-height: ${data?.systemStatus?.active && !closed ? '300px' : 0};
          opacity: ${data?.systemStatus?.active && !closed ? 1 : 0};
          transition-property: max-height, opacity;
          transition-duration: 0.5s;
          transition-timing-function: linear;
        `}
      >
        <Alert
          showIcon
          banner
          type={type}
          message={data?.systemStatus?.heading}
          description={
            <div
              className={css`
                white-space: pre-wrap;
              `}
            >
              {data?.systemStatus?.content}
            </div>
          }
          action={
            <Space size="small">
              {data?.systemStatus?.link && (
                <Button
                  icon={<ExportOutlined />}
                  onClick={() => (data?.systemStatus?.link ? openExternalLink(data.systemStatus.link) : null)}
                >
                  Weitere Informationen
                </Button>
              )}
              <Button type="text" icon={<CloseOutlined />} onClick={() => setClosed(true)} />
            </Space>
          }
          className={css`
            .ant-alert-message {
              white-space: nowrap;
            }

            .ant-alert-description {
              max-height: 100px;
              overflow: auto;
            }
          `}
        />
      </div>
      <div
        className={css`
          flex-grow: 1;
          overflow-y: auto;
        `}
      >
        {children}
      </div>
    </Flex>
  );
};
