import React, { useEffect } from 'react';
import { App, Modal } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { CatalogForm, type ParameterFragment } from './CatalogForm';
import { useForm } from 'antd/es/form/Form';
import { FragmentType, graphql } from '../../../../graphql/generated';
import { CatalogCreateInput, CatalogUpdateInput } from '../../../../graphql/generated/graphql.ts';
import { LoadingIndicator } from '../../../../components/LoadingIndicator.tsx';

export const CATALOG_QUERY = graphql(`
  query Catalog($catalogId: ID!) {
    catalog(id: $catalogId) {
      id
      name
      description
      catalogParameters {
        id
        parameter {
          id
        }
      }
    }
  }
`);

const UPDATE_CATALOG_MUTATION = graphql(`
  mutation UpdateCatalog($id: ID!, $input: CatalogUpdateInput!) {
    updateCatalog(id: $id, input: $input) {
      id
    }
  }
`);

export const UpdateCatalogModal: React.FC<{
  catalogId: string | null;
  parameterFragments: FragmentType<typeof ParameterFragment>[];
  onClose: () => void;
  onSuccess: () => void;
}> = ({ catalogId, parameterFragments, onClose, onSuccess }) => {
  const [updateCatalogMutation, { loading: mutationLoading }] = useMutation(UPDATE_CATALOG_MUTATION);
  const [form] = useForm<CatalogCreateInput | CatalogUpdateInput>();
  const { message } = App.useApp();

  const { data, loading } = useQuery(CATALOG_QUERY, {
    variables: {
      catalogId: catalogId ?? '',
    },
    skip: catalogId === null,
  });

  const update = async (values: CatalogUpdateInput) => {
    try {
      await updateCatalogMutation({
        variables: {
          id: catalogId ?? '',
          input: { ...values },
        },
      });

      message.success('Parameterkatalog wurde aktualisiert');
      onSuccess();
      onClose();
    } catch {
      message.error('Parameterkatalog konnte nicht aktualisiert werden');
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      name: data?.catalog?.name ?? '',
      description: data?.catalog?.description ?? '',
      parameterIds: data?.catalog?.catalogParameters.map(p => p.parameter.id) ?? [],
    });
  }, [form, data]);

  return (
    <Modal
      width={768}
      title="Parameterkatalog bearbeiten"
      okText="Aktualisieren"
      okButtonProps={{ icon: <EditOutlined /> }}
      open={!!catalogId}
      onCancel={onClose}
      onOk={form.submit}
      confirmLoading={mutationLoading}
      destroyOnClose
    >
      {loading || !catalogId /* performance improvement */ ? (
        <LoadingIndicator height="200px" />
      ) : (
        <CatalogForm form={form} onFinish={update} parameterFragments={parameterFragments} />
      )}
    </Modal>
  );
};
