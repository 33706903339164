import React, { ComponentType, MouseEventHandler, ReactNode } from 'react';
import { Card, Typography } from 'antd';
import { LoadingIndicator } from './LoadingIndicator';
import { css } from '@emotion/css';

export const DashboardEntry: React.FC<{
  onClick: MouseEventHandler<HTMLDivElement>;
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  icon: ComponentType<any>;
  value: number | string;
  title: string;
  loading: boolean;
  color: string;
  disabled?: boolean;
  extra?: ReactNode;
}> = ({ onClick, icon: IconComponent, value, title, loading, color, disabled, extra }) => {
  const valueColor = value === 0 ? 'rgba(0,0,0,0.45)' : color;
  const opacity = disabled ? '0.4' : '1';
  return (
    <Card hoverable onClick={disabled ? () => {} : onClick}>
      {loading ? (
        <div
          className={css`
            margin-top: 29px;
          `}
        >
          <LoadingIndicator />
        </div>
      ) : (
        <Typography.Title
          className={css`
            margin-top: 15px;
            margin-bottom: 0 !important;
            text-align: center;
          `}
          style={{ color: valueColor, opacity: opacity }}
        >
          <IconComponent style={{ color: color }} /> {value}
        </Typography.Title>
      )}
      <div
        className={css`
          margin: 0;
          height: 55px;
          display: flex;
          justify-content: center;
          align-items: center;

          h3 {
            margin: 0;
            text-align: center;
            vertical-align: middle;
          }
        `}
      >
        <h3 style={{ opacity: opacity }}>
          {title} {extra}
        </h3>
      </div>
    </Card>
  );
};
