import React from 'react';
import { Tag, theme } from 'antd';
import { HomeOutlined, PhoneOutlined } from '@ant-design/icons';
import { personAge, SHORT_DATE_FORMAT } from '../../utils/dateFormatUtils';
import { insuranceName, patientAddress } from '../../utils/patient';
import dayjs from 'dayjs';
import { css } from '@emotion/css';
import { useQuery } from '@apollo/client';
import { graphql } from '../../graphql/generated';
import { Gender, PatientData } from '../../graphql/generated/graphql.ts';

const { useToken } = theme;

const PATIENT_INFO_QUERY = graphql(`
  query PatientInfo {
    insurances {
      id
      code
      shortName
    }
  }
`);

export const PatientInfo: React.FC<{ patientData: PatientData }> = ({ patientData }) => {
  const { token } = useToken();
  const address = patientAddress(patientData);

  const { data } = useQuery(PATIENT_INFO_QUERY);

  const tagStyle = css`
    margin-bottom: ${token.paddingXS}px;
  `;

  return (
    <>
      <h4>
        {patientData.title} {patientData.firstName} {patientData.lastName}
      </h4>
      <div>
        <Tag className={tagStyle}>
          {patientData.gender === Gender.FEMALE ? 'W' : 'M'} {personAge(patientData.birthday).toLocaleString('de-AT')}{' '}
          Jahre
        </Tag>
        <Tag className={tagStyle}>
          {patientData.svnr ? (
            <span>
              {patientData.svnr} ({dayjs(patientData.birthday).format(SHORT_DATE_FORMAT)})
            </span>
          ) : (
            <span>{dayjs(patientData.birthday).format(SHORT_DATE_FORMAT)}</span>
          )}
        </Tag>
        <Tag className={tagStyle}>{insuranceName(patientData, data?.insurances ?? [])}</Tag>
        <Tag className={tagStyle} hidden={!patientData.phone.length} icon={<PhoneOutlined />}>
          {patientData.phone}
        </Tag>
        <Tag className={tagStyle} hidden={!address.length} icon={<HomeOutlined />}>
          {address}
        </Tag>
      </div>
    </>
  );
};
