import React from 'react';
import {
  Checkbox,
  DatePicker,
  Descriptions,
  Form as AntForm,
  FormInstance,
  Input,
  Radio,
  Select,
  Space,
  theme,
  Typography,
} from 'antd';
import { css } from '@emotion/css';
import { FormLabel } from '../../components/FormLabel';
import { SHORT_DATE_FORMAT } from '../../utils/dateFormatUtils';
import { graphql } from '../../graphql/generated';
import { FormFieldType, FormRenderer_FormFragment } from '../../graphql/generated/graphql.ts';
import { Rule } from 'rc-field-form/lib/interface';

const { useToken } = theme;

export const FormRendererFragment = graphql(`
  fragment FormRenderer_Form on Form {
    id
    name
    description
    header
    fields {
      id
      defaultValue
      regex
      help
      label
      required
      type
      placeholder
      name
      options {
        label
        selected
        value
      }
    }
  }
`);

const renderFormField = (field: FormRenderer_FormFragment['fields'][number]) => {
  switch (field.type) {
    case FormFieldType.INPUT:
      return <Input placeholder={field.placeholder} />;
    case FormFieldType.DATE:
      return <DatePicker style={{ width: '100%' }} format={SHORT_DATE_FORMAT} placeholder={field.placeholder} />;
    case FormFieldType.SELECT:
      return (
        <Select
          style={{ width: '100%' }}
          placeholder={field.placeholder}
          allowClear
          options={field.options.map(option => ({
            label: option.label,
            value: option.value,
          }))}
        />
      );
    case FormFieldType.OPTION_GROUP:
      return (
        <Radio.Group
          options={field.options.map(option => ({
            label: option.label,
            value: option.value,
          }))}
        />
      );
    case FormFieldType.CHECKBOX_GROUP:
      return (
        <Checkbox.Group>
          <Space direction="vertical">
            {field.options.map((option, index) => (
              <Checkbox key={index} value={option.value}>
                {option.label}
              </Checkbox>
            ))}
          </Space>
        </Checkbox.Group>
      );
    default:
      return <Typography.Text type="danger">Unbekannter Feldtyp {field.type}</Typography.Text>;
  }
};

const mapRules = (field: { type: FormFieldType; required: boolean; regex: string }) => {
  const rules: Rule[] = [];
  switch (field.type) {
    case FormFieldType.INPUT:
      rules.push({
        type: 'string',
        required: field.required,
        whitespace: field.required,
      });
      if (field.regex.length) {
        rules.push({
          type: 'string',
          pattern: new RegExp(field.regex),
          message: 'Die Eingabe entspricht nicht dem benötigten Format',
        });
      }
      break;
    case FormFieldType.CHECKBOX_GROUP:
      rules.push({
        type: 'array',
        required: field.required,
      });
      break;
    case FormFieldType.DATE:
      rules.push({
        type: 'date',
        required: field.required,
      });
      break;
    case FormFieldType.OPTION_GROUP:
    case FormFieldType.SELECT:
      rules.push({
        type: 'string',
        required: field.required,
      });
  }
  return rules;
};

export const FormRenderer: React.FC<{
  formInstance: FormInstance;
  forms: FormRenderer_FormFragment[];
  onChange?: () => void;
}> = ({ formInstance, forms, onChange }) => {
  const { token } = useToken();

  return (
    <AntForm
      form={formInstance}
      onChange={onChange}
      initialValues={forms
        .flatMap(it => it.fields)
        .reduce((result, field) => {
          if (field.type === FormFieldType.INPUT) {
            result[field.id] = field.defaultValue;
          } else if (field.type === FormFieldType.SELECT || field.type === FormFieldType.OPTION_GROUP) {
            result[field.id] = field.options.find(it => it.selected)?.value;
          } else if (field.type === FormFieldType.CHECKBOX_GROUP) {
            result[field.id] = field.options.filter(it => it.selected).map(it => it.value);
          }
          return result;
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        }, {} as any)}
    >
      <Space direction="vertical" size="large" style={{ width: '100%' }}>
        {forms
          .filter(it => it.fields.length)
          .map(form => {
            return (
              <Descriptions
                key={form.id}
                column={1}
                size="small"
                bordered
                styles={{ label: { width: '40%' } }}
                title={
                  <>
                    {form.header}
                    <div
                      className={css`
                        font-weight: normal;
                        white-space: pre-wrap;
                        font-size: ${token.fontSize}px;
                        line-height: ${token.lineHeight};
                        color: ${token.colorTextSecondary};
                      `}
                    >
                      {form.description}
                    </div>
                  </>
                }
              >
                {form.fields.map(field => (
                  <Descriptions.Item
                    key={field.id}
                    label={
                      <FormLabel required={field.required} help={field.help} label={field.label} htmlFor={field.id} />
                    }
                  >
                    <AntForm.Item
                      name={field.id}
                      messageVariables={{
                        label: field.label,
                      }}
                      className={css`
                        margin-bottom: 0;

                        div:nth-child(2) {
                          display: block !important;
                        }
                      `}
                      rules={mapRules(field)}
                    >
                      {renderFormField(field)}
                    </AntForm.Item>
                  </Descriptions.Item>
                ))}
              </Descriptions>
            );
          })}
      </Space>
    </AntForm>
  );
};
