import React, { DragEvent, useState } from 'react';
import { Alert, App, Select, Space, Upload } from 'antd';
import { CloudUploadOutlined, LoadingOutlined } from '@ant-design/icons';
import { useAuth } from 'react-oidc-context';
import { graphql } from '../../../graphql/generated';
import { useQuery } from '@apollo/client';

const { Dragger } = Upload;
const allowedMimeTypes = ['text/csv'];

const IMPORT_LOOKUP_DOCTOR_QUERY = graphql(`
  query ImportLookupDoctor {
    labs {
      id
      identifier
      name
    }
  }
`);

export const ImportLookupDoctor: React.FC = () => {
  const [uploading, setUploading] = useState(false);
  const [selectedLabId, setSelectedLabId] = useState<string>('');
  const { message } = App.useApp();
  const auth = useAuth();

  const { data, loading } = useQuery(IMPORT_LOOKUP_DOCTOR_QUERY);

  const onDrop = (event: DragEvent) => {
    if (Array.from(event.dataTransfer.files).some(f => !allowedMimeTypes.includes(f.type))) {
      message.error('Nur CSV-Dateien sind erlaubt');
    }
  };

  const beforeUpload = (file: File) => {
    const mimeTypeOk = allowedMimeTypes.find(mimeType => mimeType === file.type);
    if (!mimeTypeOk) {
      message.error('Nur CSV-Dateien sind erlaubt');
    }
    const fileSizeOk = file.size / 1024 / 1024 < 10;
    if (!fileSizeOk) {
      message.error('Datei muss kleiner als 10 MB sein');
    }
    if (mimeTypeOk && fileSizeOk) {
      upload(file);
    }
    return false;
  };

  const upload = async (file: File) => {
    setUploading(true);
    const endpoint = window._env_.API_URL + '/rest/doctor-import';
    const formData = new FormData();
    formData.append('file', file);
    formData.append('labId', selectedLabId);

    try {
      const response = await fetch(endpoint, {
        method: 'POST',
        body: formData,
        headers: { authorization: `Bearer ${auth.user?.access_token}` },
      });

      if (!response.ok) {
        message.error('Import fehlgeschlagen');
        return;
      }
      message.success('Import erfolgreich');
    } catch {
      message.error('Import fehlgeschlagen');
    } finally {
      setUploading(false);
    }
  };

  return (
    <Space direction="vertical" style={{ width: '400px' }}>
      <Select
        style={{ width: '100%' }}
        showSearch
        placeholder="Bitte Labor auswählen"
        optionFilterProp="search"
        options={
          data?.labs?.map(it => ({
            label: it.name,
            value: it.identifier,
            search: it.name,
          })) ?? []
        }
        onSelect={v => setSelectedLabId(v)}
      />
      <Dragger
        disabled={loading || !data || uploading || !selectedLabId}
        showUploadList={false}
        beforeUpload={beforeUpload}
        accept=".csv"
      >
        <div
          onDrop={onDrop}
          style={{
            display: 'flex',
            height: '150px',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <p className="ant-upload-drag-icon">{uploading ? <LoadingOutlined /> : <CloudUploadOutlined />}</p>
          <p className="ant-upload-hint">
            Klicken Sie hier, oder ziehen Sie die Datei in diesen Bereich, um den Vorgang zu starten. Erlaubter
            Dateityp: CSV
          </p>
        </div>
      </Dragger>
      <Alert
        type="info"
        showIcon
        message="Es wird immer die komplette Liste für das ausgewählte Labor importiert. Bereits vorhandene od. importierte Daten werden dabei gelöscht."
      />
    </Space>
  );
};
