import React, { DragEvent, useCallback, useEffect, useState } from 'react';
import { MainContent } from '../../../components/MainContent';
import { useParams } from 'react-router';
import { useMutation, useQuery } from '@apollo/client';
import { App, Button, Popconfirm, Space, Upload } from 'antd';
import {
  CloudDownloadOutlined,
  CloudUploadOutlined,
  DeleteOutlined,
  EyeOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { PdfPreviewModal } from '../../../components/PdfPreviewModal';
import { saveUrl } from '../../../utils/helpers';
import { graphql } from '../../../graphql/generated';
import { useAuth } from 'react-oidc-context';

const { Dragger } = Upload;
const allowedMimeTypes = ['application/pdf'];

const DELETE_LAB_TRANSFER_DOCUMENT_MUTATION = graphql(`
  mutation DeleteLabTransferDocument($id: ID!) {
    deleteLabTransferDocument(id: $id) {
      id
    }
  }
`);

const DELETE_LAB_AIS_EXPORT_DOCUMENT_MUTATION = graphql(`
  mutation DeleteLabAisExportDocument($id: ID!) {
    deleteLabAisExportDocument(id: $id) {
      id
    }
  }
`);

const LAB_QUERY = graphql(`
  query DocumentsLab($id: ID!) {
    lab(id: $id) {
      id
      hasTransferDocument
      hasAisExportDocument
    }
  }
`);

export const Documents: React.FC = () => {
  const { id: labId } = useParams<{ id: string }>();
  const [aisExportDocumentUploading, setAisExportDocumentUploading] = useState(false);
  const [transferDocumentUploading, setTransferDocumentUploading] = useState(false);
  const [transferDocumentUrl, setTransferDocumentUrl] = useState('');
  const [aisExportDocumentUrl, setAisExportDocumentUrl] = useState('');
  const [preview, setPreview] = useState<{ url: string; title: string } | null>(null);
  const auth = useAuth();
  const { message } = App.useApp();

  const { data } = useQuery(LAB_QUERY, {
    variables: {
      id: labId ?? '',
    },
    pollInterval: 60000,
  });

  const loadTransferDocument = useCallback(async () => {
    setTransferDocumentUrl('');
    if (!data?.lab?.id) {
      return;
    }
    const endpoint = window._env_.API_URL + `/rest/lab/${data?.lab.id}/transferDocumentUrl`;
    const response = await fetch(endpoint, {
      headers: { authorization: `Bearer ${auth.user?.access_token}` },
    });
    const url = await response.text();
    setTransferDocumentUrl(url);
  }, [auth, data]);

  const loadAisExportDocument = useCallback(async () => {
    setAisExportDocumentUrl('');
    if (!data?.lab?.id) {
      return;
    }
    const endpoint = window._env_.API_URL + `/rest/lab/${data?.lab.id}/aisExportDocumentUrl`;
    const response = await fetch(endpoint, {
      headers: { authorization: `Bearer ${auth.user?.access_token}` },
    });
    const url = await response.text();
    setAisExportDocumentUrl(url);
  }, [auth, data]);

  useEffect(() => {
    loadTransferDocument();
    loadAisExportDocument();
  }, [data, loadTransferDocument, loadAisExportDocument, auth]);

  const [deleteLabTransferDocumentMutation] = useMutation(DELETE_LAB_TRANSFER_DOCUMENT_MUTATION);
  const [deleteAisExportDocumentMutation] = useMutation(DELETE_LAB_AIS_EXPORT_DOCUMENT_MUTATION);

  const deleteTransferDocument = async () => {
    try {
      await deleteLabTransferDocumentMutation({
        variables: {
          id: data?.lab?.id ?? '',
        },
      });

      message.success('Überweisungsschein wurde gelöscht');
      loadTransferDocument();
    } catch {
      message.error('Überweisungsschein konnte nicht gelöscht werden');
    }
  };

  const deleteAisExportDocument = async () => {
    try {
      await deleteAisExportDocumentMutation({
        variables: {
          id: data?.lab?.id ?? '',
        },
      });

      message.success('AIS-Export Vorlage wurde gelöscht');
      loadAisExportDocument();
    } catch {
      message.error('AIS-Export Vorlage konnte nicht gelöscht werden');
    }
  };

  const showTransferDocument = () => {
    setPreview({ url: transferDocumentUrl, title: 'Überweisungsschein Vorlage' });
  };

  const showAisExportDocument = () => {
    setPreview({ url: aisExportDocumentUrl, title: 'AIS-Export Vorlage' });
  };

  const downloadTransferDocument = () =>
    saveUrl(transferDocumentUrl, 'ueberweisungsschein.pdf', () =>
      message.error('Datei konnte nicht heruntergeladen werden')
    );

  const downloadAisExportDocument = () =>
    saveUrl(aisExportDocumentUrl, 'ais-export.pdf', () => message.error('Datei konnte nicht heruntergeladen werden'));

  const onDrop = (event: DragEvent) => {
    if (Array.from(event.dataTransfer.files).some(f => !allowedMimeTypes.includes(f.type))) {
      message.error('Nur PDF-Dateien sind erlaubt');
    }
  };

  const beforeUploadTransferDocument = (file: File) => {
    const mimeTypeOk = allowedMimeTypes.find(mimeType => mimeType === file.type);
    if (!mimeTypeOk) {
      message.error('Nur PDF-Dateien sind erlaubt');
    }
    const fileSizeOk = file.size / 1024 / 1024 < 0.5;
    if (!fileSizeOk) {
      message.error('Datei muss kleiner als 0,5 MB sein');
    }
    if (mimeTypeOk && fileSizeOk) {
      uploadTransferDocument(file);
    }
    return false;
  };

  const uploadTransferDocument = async (file: File) => {
    setTransferDocumentUploading(true);
    const endpoint = window._env_.API_URL + `/rest/lab/${data?.lab?.id}/transferDocument`;
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await fetch(endpoint, {
        method: 'POST',
        body: formData,
        headers: { authorization: `Bearer ${auth.user?.access_token}` },
      });
      const body = await response.text();
      if (response.ok) {
        message.success('Upload erfolgreich');
      } else {
        message.error('Upload fehlgeschlagen: ' + body);
      }
      loadTransferDocument();
    } catch (e) {
      message.error('Upload fehlgeschlagen: ' + e);
    } finally {
      setTransferDocumentUploading(false);
    }
  };

  const beforeUploadAisExportDocument = (file: File) => {
    const mimeTypeOk = allowedMimeTypes.find(mimeType => mimeType === file.type);
    if (!mimeTypeOk) {
      message.error('Nur PDF-Dateien sind erlaubt');
    }
    const fileSizeOk = file.size / 1024 / 1024 < 0.5;
    if (!fileSizeOk) {
      message.error('Datei muss kleiner als 0,5 MB sein');
    }
    if (mimeTypeOk && fileSizeOk) {
      uploadAisExportDocument(file);
    }
    return false;
  };

  const uploadAisExportDocument = async (file: File) => {
    setAisExportDocumentUploading(true);
    const endpoint = window._env_.API_URL + `/rest/lab/${data?.lab?.id}/aisExportDocument`;
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await fetch(endpoint, {
        method: 'POST',
        body: formData,
        headers: { authorization: `Bearer ${auth.user?.access_token}` },
      });
      const body = await response.text();
      if (response.ok) {
        message.success('Upload erfolgreich');
      } else {
        message.error('Upload fehlgeschlagen: ' + body);
      }
      loadAisExportDocument();
    } catch (e) {
      message.error('Upload fehlgeschlagen: ' + e);
    } finally {
      setAisExportDocumentUploading(false);
    }
  };

  return (
    <MainContent>
      <h3>Überweisungsschein</h3>
      {transferDocumentUrl && (
        <>
          <Space direction="horizontal">
            <Button icon={<EyeOutlined />} onClick={showTransferDocument}>
              Anzeigen
            </Button>
            <Button icon={<CloudDownloadOutlined />} onClick={downloadTransferDocument}>
              Download
            </Button>
            <Popconfirm
              title="Überweisungsschein wirklich löschen?"
              onConfirm={deleteTransferDocument}
              okText="Ja"
              okButtonProps={{ danger: true }}
              cancelText="Nein"
            >
              <Button danger icon={<DeleteOutlined />}>
                Löschen
              </Button>
            </Popconfirm>
          </Space>
          <p />
        </>
      )}
      <Dragger showUploadList={false} beforeUpload={beforeUploadTransferDocument} accept=".pdf">
        <div
          onDrop={onDrop}
          style={{
            display: 'flex',
            height: '150px',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <p className="ant-upload-drag-icon">
            {transferDocumentUploading ? <LoadingOutlined /> : <CloudUploadOutlined />}
          </p>
          <p className="ant-upload-text">Neuen Überweisungsschein hochladen</p>
          <p className="ant-upload-hint">
            Klicken Sie hier, oder ziehen Sie die gewünschte Datei in diesen Bereich, um den Upload zu starten.
            Erlaubter Dateityp: PDF
          </p>
        </div>
      </Dragger>
      <p />
      <h3>AIS-Export Vorlage</h3>
      {aisExportDocumentUrl && (
        <>
          <Space direction="horizontal">
            <Button icon={<EyeOutlined />} onClick={showAisExportDocument}>
              Anzeigen
            </Button>
            <Button icon={<CloudDownloadOutlined />} onClick={downloadAisExportDocument}>
              Download
            </Button>
            <Popconfirm
              title="AIS-Export Vorlage wirklich löschen?"
              onConfirm={deleteAisExportDocument}
              okText="Ja"
              okButtonProps={{ danger: true }}
              cancelText="Nein"
            >
              <Button danger icon={<DeleteOutlined />}>
                Löschen
              </Button>
            </Popconfirm>
          </Space>
          <p />
        </>
      )}
      <Dragger showUploadList={false} beforeUpload={beforeUploadAisExportDocument} accept=".pdf">
        <div
          onDrop={onDrop}
          style={{
            display: 'flex',
            height: '150px',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <p className="ant-upload-drag-icon">
            {aisExportDocumentUploading ? <LoadingOutlined /> : <CloudUploadOutlined />}
          </p>
          <p className="ant-upload-text">Neue AIS-Export Vorlage hochladen</p>
          <p className="ant-upload-hint">
            Klicken Sie hier, oder ziehen Sie die gewünschte Datei in diesen Bereich, um den Upload zu starten.
            Erlaubter Dateityp: PDF
          </p>
        </div>
      </Dragger>
      <PdfPreviewModal
        title={preview?.title ?? ''}
        url={preview?.url ?? ''}
        loading={false}
        onClose={() => setPreview(null)}
      />
    </MainContent>
  );
};
