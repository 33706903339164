import React, { useEffect } from 'react';
import { Alert, App, Modal, Space } from 'antd';
import { EditOutlined, WarningTwoTone } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { CatalogParameterForm } from './CatalogParameterForm';
import { useForm } from 'antd/es/form/Form';
import { graphql } from '../../../../graphql/generated';
import { CatalogParameterUpdateInput } from '../../../../graphql/generated/graphql.ts';
import { LoadingIndicator } from '../../../../components/LoadingIndicator.tsx';
import { css } from '@emotion/css';
import { gold } from '@ant-design/colors';

const UPDATE_CATALOG_PARAMETERS_MUTATION = graphql(`
  mutation UpdateCatalogParameters($ids: [ID!]!, $input: CatalogParameterUpdateInput!) {
    updateCatalogParameters(ids: $ids, input: $input) {
      id
    }
  }
`);

const MULTI_CATALOG_PARAMETERS_QUERY = graphql(`
  query MultiCatalogParameters($ids: [ID!]!) {
    individualCatalogParameters(ids: $ids) {
      id
      insuranceDefinitions {
        id
        insurance {
          id
        }
        diagnoses {
          id
        }
        periodicGroup {
          id
        }
        lg
      }
      specialRates {
        id
      }
      specimens {
        id
      }
      forms {
        id
      }
      useForms
      useLgDoctorBilling
      lgDoctorBilling
      useLgInsuranceDefinitions
      useSpecimens
      doctorBilling
      patientBilling
      useDoctorBilling
      useInsuranceDefinitions
      usePatientBilling
      useSpecialRates
      priceDoctor
      priceLgDoctor
      pricePatient
      usePriceDoctor
      usePriceLgDoctor
      usePricePatient
      parameter {
        id
        shortName
        specimens {
          id
        }
        withParameters {
          id
        }
        withoutParameters {
          id
        }
        insuranceDefinitions {
          id
          insurance {
            id
          }
          diagnoses {
            id
          }
          periodicGroup {
            id
          }
          lg
        }
        specialRates {
          id
        }
        specimens {
          id
        }
        forms {
          id
        }
        deactivated
        doctorBilling
        patientBilling
        link
        edifactNumber
        description
        lgDoctorBilling
        pricePatient
        priceDoctor
        priceLgDoctor
        synonyms
      }
    }
  }
`);

export const UpdateCatalogParametersModal: React.FC<{
  onClose: () => void;
  onSuccess: () => void;
  labId: string;
  cpIds: string[];
  visible: boolean;
}> = ({ onClose, onSuccess, labId, cpIds, visible }) => {
  const [updateCatalogParametersMutation] = useMutation(UPDATE_CATALOG_PARAMETERS_MUTATION);
  const [form] = useForm<CatalogParameterUpdateInput>();
  const { message } = App.useApp();

  const update = async (values: CatalogParameterUpdateInput) => {
    try {
      await updateCatalogParametersMutation({
        variables: {
          ids: cpIds,
          input: {
            ...values,
            insuranceDefinitions: form.getFieldValue('insuranceDefinitions'),
          },
        },
      });

      message.success('Katalog-Parameter wurden aktualisiert');
      onSuccess();
      onClose();
    } catch (e) {
      message.error('Katalog-Parameter konnten nicht aktualisiert werden');
    }
  };

  const { data, loading } = useQuery(MULTI_CATALOG_PARAMETERS_QUERY, {
    variables: {
      ids: cpIds,
    },
    skip: !cpIds.length,
  });

  useEffect(() => {
    let catalogParameter = null;
    if (data?.individualCatalogParameters?.length === 1) {
      catalogParameter = data.individualCatalogParameters[0];
    }

    form.setFieldsValue({
      insuranceDefinitions:
        catalogParameter?.insuranceDefinitions.map(it => ({
          insuranceId: it.insurance.id,
          diagnoseIds: it.diagnoses.map(d => d.id),
          lg: it.lg,
          periodicGroupId: it.periodicGroup?.id,
        })) ?? [],
      specialRateIds: catalogParameter?.specialRates.map(sr => sr.id) ?? [],
      doctorBilling: catalogParameter?.doctorBilling ?? false,
      patientBilling: catalogParameter?.patientBilling ?? false,
      usePatientBilling: catalogParameter?.usePatientBilling ?? false,
      useDoctorBilling: catalogParameter?.useDoctorBilling ?? false,
      useInsuranceDefinitions: catalogParameter?.useInsuranceDefinitions ?? false,
      useSpecialRates: catalogParameter?.useSpecialRates ?? false,
      useSpecimens: catalogParameter?.useSpecimens ?? false,
      specimenIds: catalogParameter?.specimens.map(sr => sr.id) ?? [],
      lgDoctorBilling: catalogParameter?.lgDoctorBilling ?? false,
      useLgDoctorBilling: catalogParameter?.useLgDoctorBilling ?? false,
      useLgInsuranceDefinitions: catalogParameter?.useLgInsuranceDefinitions ?? false,
      useForms: catalogParameter?.useForms ?? false,
      formIds: catalogParameter?.forms.map(it => it.id) ?? [],
      usePricePatient: catalogParameter?.usePricePatient ?? false,
      usePriceDoctor: catalogParameter?.usePriceDoctor ?? false,
      usePriceLgDoctor: catalogParameter?.usePriceLgDoctor ?? false,
      pricePatient: catalogParameter?.pricePatient ?? null,
      priceDoctor: catalogParameter?.priceDoctor ?? null,
      priceLgDoctor: catalogParameter?.priceLgDoctor ?? null,
    });
  }, [form, data]);

  return (
    <Modal
      width={1024}
      title={
        data?.individualCatalogParameters?.length === 1 ? (
          <>Katalog-Parameter »{data.individualCatalogParameters[0].parameter.shortName}« bearbeiten</>
        ) : loading ? (
          'Bitte warten...'
        ) : (
          <Space
            direction="vertical"
            className={css`
              width: 100%;
            `}
          >
            Batch-Bearbeitung mehrerer Katalog-Parameter
            <Alert
              message="Achtung! Alle Werte werden überschrieben. Es müssen alle Werte gesetzt werden."
              type="warning"
              showIcon
              icon={<WarningTwoTone twoToneColor={gold[5]} />}
            />
          </Space>
        )
      }
      okText={data?.individualCatalogParameters?.length === 1 ? 'Aktualisieren' : 'Alle aktualisieren'}
      okButtonProps={{ disabled: loading, loading: loading, icon: <EditOutlined /> }}
      open={visible}
      onCancel={onClose}
      onOk={form.submit}
      destroyOnClose
    >
      {loading ? (
        <LoadingIndicator height="200px" />
      ) : (
        <CatalogParameterForm form={form} onFinish={update} labId={labId} />
      )}
    </Modal>
  );
};
